import {mapActions, mapGetters} from "vuex";
const _ = require('lodash');

export default {
    props:[
        'orderData',
        'onlyRemember'
    ],
    data(){
        return{
            remember:{
                date: this.$moment(new Date()).format('DD.MM.YYYY'),
                time:'00:00',
                type:0,
                description:'',
                created_at:this.$moment(new Date()).format('DD.MM.YYYY HH:mm'),
                status:0,
            },
            type:[
                {
                    id:0,
                    title:'Перезвонить позже (заказ)'
                },
                {
                    id:1,
                    title:'Обычное напоминание'
                },
            ]
        }
    },
    computed:{
      ...mapGetters({
          profile:'profile/profile',
      })
    },
    created() {
        if(this.onlyRemember){
            this.type = [
                {
                    id:1,
                    title:'Обычное напоминание'
                },
            ]
            this.remember.type = 1;
        }
    },
    methods:{
        closePopup(){
                this.$emit('closePopup')
        },
        createRemember(){
            if(this.remember.type === 0){
                let price = 0;
                this.orderData.products.map(item=>{
                    price += item.price * item.quantity;
                })
                this.remember.id = this.orderData.id
                this.remember.customer_name = this.orderData.customer_name
                this.remember.phone = this.orderData.phone
                this.remember.price = price
            }
            this.remember.notification_id = this.$moment().format("DD MM YYYY HH mm ss").split(' ').join('');
            if( this.profile.options){
                let options = _.clone(this.profile.options);
                options.remember.push({
                    ...this.remember
                })
                const payload = {
                    user_id: this.profile.id,
                    options:{
                        remember:[
                            ...options.remember
                        ]
                    }
                }
                this.sendEditUser(payload);
            }else{
                const payload = {
                    user_id: this.profile.id,
                    options:{
                        remember:[
                            {...this.remember}
                        ]
                    }
                }
                this.sendEditUser(payload);
            }
        },
        skip(){
            const payload = {
                user_id: this.profile.id,
                options:{
                    remember:[
                    ]
                }
            }
            this.sendEditUser(payload);
        },
        sendEditUser(payload){
            this.editUser(payload).then(res=>{
                if(res.status){
                    this.getProfile().then(()=>{
                            this.$toasted.success('Напоминание успешно создано',{
                                duration : 3000
                            });
                            this.$emit('closePopup')
                    })
                }
            })
        },
        ...mapActions({
            editUser:'contacts/editUser',
            getProfile: 'profile/getProfile',
        })
    }
}
import {mapActions, mapGetters, mapMutations} from 'vuex';
import Header from '@/components/header/index.vue'
import leftBar from '@/components/left-bar/index.vue'
import Table from '@/components/table/list/index.vue'
import TableOptions from '@/components/table/options/index.vue'
import popup from "@/components/popups/templates/smsTemplates/index.vue";
import pagination from '@/components/pagination/index.vue'
import paginationMixin from '@/mixins/pagination/index.js'

export default {
    components:{
        Header,
        leftBar,
        Table,
        pagination,
        TableOptions,
        popup,
    },
    mixins: [paginationMixin],
    data(){
        return{
            fields:[
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key: 'sms_title',
                    label: 'Название'
                },
                {
                    key: 'text',
                    label: 'Тест'
                },
                {
                    key: 'type',
                    label: 'Тип'
                },
            ],
            items:[],
            showWarehousePopup: false,
            selectedItem: null,
        }
    },
    computed: {
        ...mapGetters({
            menuHide:'config/menuHide',
            smsTemplates:'templates/smsTemplates',
        })
    },
    watch:{
        smsTemplates(e){
            this.items = []
            e.data.map(item=>{
                this.items.push({
                    id:item.id,
                    'sms_title': item.title,
                    'text': item.text,
                    'type': item.type ? 'Кирилица' : 'Латиница',
                });
            })
            this.res = e
        },
        page(e) {
            this.getSmsTemplates({page: e, per_page: this.perPage});
        },
        perPage(e) {
            this.getSmsTemplates({page: this.page, perpage: e});
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getSmsTemplates({page: 1, perpage: this.perPage}).then(()=>{
            this.toggleLoadingStatus(false);
        });
    },
    methods:{
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        changePopup(item){
            this.showSmsTemplate(item.id)
            this.showWarehousePopup = true;
        },
        editClickWarehouse(item){
            this.showWarehouse(item.id)
            this.showWarehousePopup = true;
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        sendWarehouse(form){
            this.addSmsTemplate(form).then(res=>{
                if(res.status){
                    this.getSmsTemplates();
                    this.$toasted.success('Шаблон успешно добавлен',{
                        duration : 3000
                    });
                }
                this.showWarehousePopup = false;
            })
        },
        changeWarehouse(form){
            this.editSmsTemplate(form).then(res=>{
                if(res.status){
                    this.getSmsTemplates();
                    this.$toasted.success('Шаблон успешно изменен',{
                        duration : 3000
                    });
                    this.showWarehousePopup = false;
                }
            })
        },
        removeItem(id){
          this.removeSmsTemplate(id).then(res=>{
              if(res.status){
                  this.getSmsTemplates();
                  this.$toasted.success('Шаблон успешно удален',{
                      duration : 3000
                  });
                  this.showWarehousePopup = false;
              }
          })
        },
        ...mapActions({
            getSmsTemplates:'templates/getSmsTemplates',
            showSmsTemplate:'templates/showSmsTemplate',
            addSmsTemplate:'templates/addSmsTemplate',
            editSmsTemplate:'templates/editSmsTemplate',
            removeSmsTemplate:'templates/removeSmsTemplate',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    }
}

import {mapActions, mapGetters, mapMutations} from 'vuex';
import Header from '../../components/header/index.vue'
import leftBar from '../../components/left-bar/index.vue'
import warehousePopup from "@/components/popups/warehouse/index.vue";
import pagination from '@/components/pagination/index.vue'
import paginationMixin from '@/mixins/pagination/index.js'

const _ = require('lodash')
export default {
    components:{
        Header,
        leftBar,
        warehousePopup,
        pagination
    },
    mixins: [paginationMixin],
    data(){
        return{
            productSearch:{
              id:'',
              title:''
            },
            fields:[
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key: 'title',
                    label: 'Назва'
                },
                {
                    key: 'count',
                    label: 'Кількість'
                },
                {
                    key: 'availableQuantity',
                    label: 'Вільно на складі'
                },
                {
                    key: 'reserve',
                    label: 'Резерв'
                },
                {
                    key: 'warehouse',
                    label: 'Склад'
                }
            ],
            items:[],
            showWarehousePopup: false,
            selectedItem: null,
        }
    },
    computed: {
        ...mapGetters({
            menuHide:'config/menuHide',
            warehouses:'warehouse/warehouses',
            warehousesProducts:'warehouse/warehousesProducts',
        })
    },
    watch:{
        warehouses(e){
            console.log('e - ', e);
            this.warehouses.data.unshift({id: 0, title: 'Усі'})
            this.selectedItem = e.data[0].id
        },
        warehousesProducts(e){
            this.items = []
            e.data.map(item=>{
                this.items.push({
                    'title': item.title,
                     id:item.id, 
                     count: item.sum_quantity,
                     reserve: item.reserved_quantity,
                     availableQuantity: item.available_quantity ? item.available_quantity : '',
                     warehouse: item.warehouse_title ? item.warehouse_title : ''
                    });
            })
            this.res = e
        },
        page() {
            this.searchWarehouseProduct()
        },
        per_page() {
            this.searchWarehouseProduct()
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getWarehouses({page: 1, per_page: this.per_page}).then(()=>{
            this.toggleLoadingStatus(false);
        });
    },
    methods:{
        findForId: _.debounce(function(){
            this.searchWarehouseProduct();
        },600),
        findForTitle: _.debounce(function(){
            this.searchWarehouseProduct();
        },600),
        searchWarehouseProduct(){
            let searchLink = '';
            if(this.productSearch.id){
                searchLink +=  '&id='+this.productSearch.id
            }
            if(this.productSearch.title){
                searchLink +=  '&title='+this.productSearch.title
            }
            this.getWarehousesProducts({id:this.selectedItem, page: this.page, per_page: this.per_page, links: searchLink})
        },
        findWarehouseProducts(){
            console.log('this.selectedItem - ', this.selectedItem )
            this.getWarehousesProducts({id:this.selectedItem, page: 1, per_page: this.per_page})
        },
        ...mapActions({
            getWarehouses:'warehouse/getWarehouses',
            getWarehousesProducts:'warehouse/getWarehousesProducts',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    }
}

import {mapGetters} from "vuex";


export default {
    data() {
        return {
           languages: [
               {
                   code: 'uk',
                   title: 'Ukrainian'
               },
               {
                   code: 'ru',
                   title: 'Russian'
               },
               {
                   code: 'en',
                   title: 'English'
               }
           ],
            language: null,
            langs: ['uk', 'ru'],
            sounds:{
               soundList:[
                   {
                       id:0,
                       title:'Вход',
                       soundName:'LogON',
                       ico:'fa fa-sign-in',
                       color:'#000',
                       value:50,
                   },
                   {
                       id:0,
                       title:'Выход',
                       soundName:'LogOFF',
                       ico:'fa fa-sign-out',
                       color:'#000',
                       value:50,
                   },
                   {
                       id:0,
                       title:'Ошибка',
                       soundName:'ERROR',
                       ico:'fa fa-exclamation-circle',
                       color:'#ff0000',
                       value:50,
                   },
                   {
                       id:0,
                       title:'Подтверждение',
                       soundName:'CONFIRM',
                       ico:'fa fa-question-circle',
                       color:'#4285f4',
                       value:50,
                   },
                   {
                       id:0,
                       title:'Предупреждение',
                       soundName:'WARNING',
                       ico:'fa fa-warning',
                       color:'#C60',
                       value:50,
                   },
                   {
                       id:0,
                       title:'Информация',
                       soundName:'INFO',
                       ico:'fa fa-info-circle',
                       color:'#7DB6DB',
                       value:50,
                   },
                   {
                       id:0,
                       title:'Напоминание события',
                       soundName:'ALARM_CLOCK',
                       ico:'fa fa-bell',
                       color:'#000',
                       value:50,
                   },
                   {
                       id:0,
                       title:'Переключатель',
                       soundName:'BUTTON_SWITCH',
                       ico:'fa fa-toggle-on',
                       color:'#008000',
                       value:50,
                   },
                   {
                       id:0,
                       title:'Доступ заблокирован',
                       soundName:'ACCESS_LOCKED',
                       ico:'fa fa-lock',
                       color:'#000',
                       value:50,
                   },
                   {
                       id:0,
                       title:'Уведомление (мини)',
                       soundName:'MESSAGE',
                       ico:'fa fa-info-circle',
                       color:'#000',
                       value:50,
                   },
                   {
                       id:0,
                       title:'Новый заказ',
                       soundName:'NewOrder',
                       ico:'fa fa-shopping-cart',
                       color:'#000',
                       value:50,
                   },
               ]
            }
        }
    },
    computed:{
        ...mapGetters({
            profile:'profile/profile'
        })
    },
    created() {
        console.log('profile- ' , this.profile);
    },
    methods: {
        soundPlay(soundName, volume){
            const audio = new Audio(require('../../../assets/sounds/'+soundName+'.mp3'));
            volume = volume / 100;
            audio.volume = volume;
            audio.play();
        },
        closePopup() {
            this.$emit('closePopup')
        },
        saveOptions() {
            return
        }
    }
}
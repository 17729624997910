import {mapGetters, mapActions, mapMutations} from 'vuex';
import paginationMixin from '@/mixins/pagination/index.js'

export default {
    components:{
        Header: ()=> import('@/components/header/index.vue'),
        leftBar: ()=> import('@/components/left-bar/index.vue'),
        Table: ()=> import('@/components/table/list/index.vue'),
        pagination: ()=> import('@/components/pagination/index.vue'),
        TableOptions: ()=> import('@/components/table/options/index.vue'),
        popup: ()=> import('@/components/popups/orders/statusOrder/index.vue'),
        carousel: ()=> import('vue-owl-carousel'),
    },
    mixins: [paginationMixin],
    data(){
        return{
            fields:[
                {
                    key:'id',
                    label:'id',
                },
                {
                    key:'color',
                    label:'Цвет',
                },
                {
                    key:'title',
                    label:'Название',
                },
                {
                    key:'statusWarehouse',
                    label:'Склад',
                },
                {
                    key:'lock',
                    label:'Блок',
                },
                {
                    key:'reserve',
                    label:'Резерв',
                },
                {
                    key:'sort',
                    label:'Сорт',
                },
                {
                    key:'status',
                    label:'status',
                },
            ],
            items:[],
            selectedItem: null,
            showPopup: false,
        }
    },
    computed:{
        ...mapGetters({
            profile:'profile/profile',
            menuHide:'config/menuHide',
            usersGroup:'contacts/usersGroup',
            orderStatusList:'orders/orderStatusList',
        })
    },
    watch:{
        orderStatusList(e){
            if(this.profile) {
                this.items = [];
                let userGroup = null;
                if(this.usersGroup && this.usersGroup.data){
                    userGroup = this.usersGroup.data.filter(item=>item.id === this.profile.user_group_id)
                    userGroup = userGroup[0];
                }
                 userGroup.accept_to_statuses.map((groupItem, index)=>{
                    if(groupItem.value){
                        let trueItem = null;
                        if(e.data){
                            trueItem = e.data.filter(item => item.title === groupItem.title)
                            trueItem = trueItem[0];
                        }
                        if(trueItem){
                            this.items.push({
                                'id': trueItem.id ? trueItem.id : index,
                                'color': trueItem.color,
                                'title': trueItem.title,
                                'status': Boolean(trueItem.status),
                                'lock': trueItem.lock,
                                'sort': trueItem.sort,
                                'statusWarehouse': trueItem.type,
                                'reserve': trueItem.reservable,
                            })
                        }
                    }
                })
                this.res = e;
            }
            this.items.sort((a, b)=> a.sort - b.sort);

        },
        page(){
            this.getOrderStatuses({page: this.page, per_page: this.per_page});
        },
        per_page(){
            this.getOrderStatuses({page: this.page, per_page: this.per_page});
        },
    },
    created() {

        this.getUsersGroup().then(()=>{
            this.toggleLoadingStatus(true);
            this.getOrderStatuses({page: 1, per_page: this.per_page}).then(()=>{
                this.toggleLoadingStatus(false);
            })
        })
    },

    methods:{
        getSearchInfo(data){
            if(this.activeStatus !== 0){
                if(data.query){
                    this.getOrderStatuses({[data.type]: data.query,page: this.page, per_page: this.per_page, customer_group_id: this.activeStatus});
                }else{
                    this.getOrderStatuses({page: this.page, per_page: this.per_page, customer_group_id: this.activeStatus});
                }
            }else{
                if(data.query){
                    this.getOrderStatuses({[data.type]: data.query,page: this.page, per_page: this.per_page});
                }else{
                    this.getOrderStatuses({page: this.page, per_page: this.per_page});
                }
            }
        },
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        sendOrderStatus(item){
            this.addOrderStatus(item).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Статус успешно добавлен', {
                        duration: 3000
                    });
                    this.showPopup = false;
                    this.getOrderStatuses({page: 1, per_page: this.perPage});
                }
            });
        },
        changeOrderStatus(item){ 
            this.editOrderStatus(item).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Статус успешно изменен', {
                        duration: 3000
                    });
                    this.showPopup = false;
                    this.getOrderStatuses({page: 1, perpage: this.perPage});
                }
            });
        },
        toggleStatus(item){
            const payload = {
                id: item.id,
                data:{
                    title:item.title,
                    status:Number(item.status)
                }
            }
            this.editOrderStatus(payload)
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        editClickItem(item){
            this.showOrderStatus(item.id)
            this.showPopup = true;
        },
        removeItem(id){
            this.removeOrderStatus(id).then(res=>{
                if(res.status){
                    this.items = [];
                    this.getOrderStatuses({page: 1, perpage: this.perPage});
                    this.$toasted.success('Статус успешно удален',{
                        duration : 3000
                    });
                    this.showPopup = false;
                }
            })
        },
        ...mapActions({
            getOrderStatuses:'orders/getOrderStatuses',
            showOrderStatus:'orders/showOrderStatus',
            addOrderStatus:'orders/addOrderStatus',
            editOrderStatus:'orders/editOrderStatus',
            removeOrderStatus:'orders/removeOrderStatus',
            getUsersGroup:'contacts/getUsersGroup',

        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    }
}

import {mapGetters, mapActions, mapMutations} from 'vuex';
import paginationMixin from '@/mixins/pagination/index.js'
import * as moment from 'moment/moment'

export default {
    components:{
        Header: ()=> import('@/components/header/index.vue'),
        leftBar: ()=> import('@/components/left-bar/index.vue'),
        Table: ()=> import('@/components/table/list/index.vue'),
        pagination: ()=> import('@/components/pagination/index.vue'),
        TableOptions: ()=> import('@/components/table/options/index.vue'),
        lotPopup: ()=> import('@/components/popups/lot/index.vue'),
    },
    mixins: [paginationMixin],
    data() {
        return {
            fields: [
                {
                    key: 'id',
                    label: 'id',
                },
                {
                    key: 'provisioner',
                    label: 'Поставщик',
                },
                {
                    key: 'lotDate',
                    label: 'Дата',
                },
                {
                    key: 'lotPrice',
                    label: 'Сумма ГРН',
                },
                {
                    key: 'lotPriceUsd',
                    label: 'Сумма USD',
                },
                {
                   key: 'lotWarehouse',
                   label: 'Склад'
                },
                {
                    key: 'products',
                    label: 'Товар',
                    thStyle: { width: "50%" },
                },
            ],
            items: [],
            showLotPopup: false,
            selectedItem: null

        }
    },
    computed: {
        ...mapGetters({
            menuHide: 'config/menuHide',
            lots: 'lot/lots'
        })
    },
    watch: {
        lots(e) {
            this.items = []
            let key;
            for(key in e.data){
                this.items.push({
                    id: e.data[key].id,
                    'provisioner':  e.data[key].provisioner ? e.data[key].provisioner.name : '',
                    'lotDate': e.data[key].updated_at ? moment(e.data[key].updated_at).format('YYYY-MM-DD HH:mm') : moment(e.data[key].created_at).format('YYYY-MM-DD HH:mm'),
                    'lotWarehouse': e.data[key].warehouse ? e.data[key].warehouse.title : ' ',
                    'lotPrice': e.data[key].lot_products.length ? (e.data[key].lot_products.map(el => +el.buy_price * el.buy_quantity)).reduce( (acc, val) => acc + val) : ' ',
                    'lotPriceUsd': e.data[key].lot_products.length ? (e.data[key].lot_products.map(el => +el.buy_price_usd * el.buy_quantity)).reduce( (acc, val) => acc + val) : ' ',
                    'products': e.data[key].lot_products ? e.data[key].lot_products : '',
                })
            }
            this.res = e
        },
        page(e) {
            this.getLots({page: e, per_page: this.per_page});
        },
        per_page(e) {
            this.getLots({page: this.page, per_page: e});
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getLots({page: 1, per_page: this.per_page}).then(()=>{
            this.toggleLoadingStatus(false);
        })
    },
    methods: {
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        editClickLot(item) {
            this.showLot(item.id)
            this.showLotPopup = true
        },
        selectItem(item) {
            if(item.length) {
                this.selectedItem = item;
                // }else if (item.length > 1) {
                //     this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        createLot(payload) {
            this.addLot(payload).then( res => {
                if(res.status){
                    this.showLotPopup = false
                    this.$toasted.success('Лот успішно створений',{
                        duration : 3000
                    });
                    this.getLots({page: this.page, per_page: this.per_page})
                }else{
                    if(this.isJsonString(res.data.message)){
                        JSON.parse(res.data.message).map(item => {
                            this.$toasted.error(item.message,{
                                duration : 3000
                            });
                        })
                    }else{ 
                        this.$toasted.error(res.data.message,{
                            duration : 3000
                        });

                    }
                    // if(typeof JSON.parse(res.data.message) && typeof JSON.parse(res.data.message) === 'object'){
                    //     console.log('JSON.parse(res.data.message) --- ', JSON.parse(res.data.message))
                    // }else{
                    //     console.log('res.data.message --- ', res.data.message)
                    // }
                }
            })
        },
        isJsonString(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        changeLot(payload) {
            this.editLot(payload).then( () => {
                this.getLots({page: this.page, per_page: this.per_page})
                this.$toasted.success('Лот успішно змінений',{
                    duration : 3000
                });
                this.showLotPopup = false
            })
            this.showLotPopup = false
        },
        removeItem(id) {
            this.removeLot(id).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Лот успешно удален',{
                        duration : 3000
                    });
                    this.showLotPopup = false;
                    this.getLots()
                }
            })
        },
        ...mapActions({
            getLots: 'lot/getLots',
            showLot: 'lot/showLot',
            addLot: 'lot/addLot',
            editLot: 'lot/editLot',
            removeLot: 'lot/removeLot'
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    },
}

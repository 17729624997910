import notification from './modules/notification'
import statistics from './modules/statistics'
import warehouse from './modules/warehouse'
import templates from './modules/templates'
import contacts from './modules/contacts'
import profile from './modules/profile'
import plugins from './modules/plugins'
import catalog from './modules/catalog'
import config from './modules/config'
import orders from './modules/orders'
import rate from './modules/rate'
import auth from './modules/auth'
import lot from './modules/lot'

export default {
    notification,
    statistics,
    warehouse,
    templates,
    contacts,
    profile,
    catalog,
    plugins,
    config,
    orders,
    auth,
    rate,
    lot
}

import {mapGetters, mapActions, mapMutations} from 'vuex';
import Header from '@/components/header/index.vue'
import leftBar from '@/components/left-bar/index.vue'
import TableOptions from '@/components/table/options/index.vue'
import Table from '@/components/table/list/index.vue'
import popup from '@/components/popups/orders/typeDelivery/index.vue'
import pagination from '@/components/pagination/index.vue'
import paginationMixin from '@/mixins/pagination/index.js'


export default {
    components:{
        Header,
        leftBar,
        TableOptions,
        Table,
        popup,
        pagination,
    },
    mixins: [paginationMixin],
    data(){
        return{
            fields:[
                {
                    key:'id',
                    label:'id',
                },
                {
                    key:'typeOrderImage',
                    label:'ico',
                },
                {
                    key:'title',
                    label:'Название',
                },
                {
                    key:'sort',
                    label:'Сорт',
                },
            ],
            items:[],
            selectedItem: null,
            showPopup: false,
        }
    },
    computed:{
        ...mapGetters({
            menuHide:'config/menuHide',
            shipmentMethods:'orders/shipmentMethods',
        })
    },
    watch:{
        shipmentMethods(e){
            e.data.map(item=>{
                this.items.push({
                    'id': item.id,
                    'title': item.title,
                    'typeOrderImage': item.image,
                    'sort': item.sort
                })
            })
            this.res = e;
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getShipmentMethods().then(()=>{
            this.toggleLoadingStatus(false);
        })
    },

    methods:{
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        sendTypeDelivery(item){
            this.addShipmentMethods(item).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Метод успешно добавлен', {
                        duration: 3000
                    });
                    this.showPopup = false;
                    this.getShipmentMethods({page: 1, per_page: this.per_page});
                }
            });
        },
        changeTypeDelivery(item){
            this.editShipmentMethods(item).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Способ успешно изменен', {
                        duration: 3000
                    });
                    this.showPopup = false;
                    this.getShipmentMethods({page: 1, per_page: this.per_page});
                }
            });
        },
        toggleStatus(item){
            const payload = {
                id: item.id,
                data:{
                    title:item.title,
                    status:Boolean(item.status)
                }
            }
            this.editShipmentMethods(payload)
        },
        editClickItem(item){
            this.showShipmentMethods(item.id)
            this.showPopup = true;
        },
        removeItem(id){
            this.removeShipmentMethods(id).then(res=>{
                if(res.status){
                    this.items = [];
                    this.getShipmentMethods({page: 1, per_page: this.per_page});
                    this.$toasted.success('Тип успешно удален',{
                        duration : 3000
                    });
                    this.showPopup = false;
                }
            })
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        ...mapActions({
            getShipmentMethods:'orders/getShipmentMethods',
            showShipmentMethods:'orders/showShipmentMethods',
            addShipmentMethods:'orders/addShipmentMethods',
            editShipmentMethods:'orders/editShipmentMethods',
            removeShipmentMethods:'orders/removeShipmentMethods',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    }
}

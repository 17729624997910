import {mapGetters, mapActions, mapMutations} from 'vuex';
import paginationMixin from '@/mixins/pagination/index.js'

export default {
    components:{
        Header: () => import('@/components/header/index.vue'),
        leftBar: () => import('@/components/left-bar/index.vue'),
        Table: () => import('@/components/table/list/index.vue'),
        pagination: () => import('@/components/pagination/index.vue'),
        TableOptions: () => import('@/components/table/options/index.vue'),
        popupProduct: () => import('@/components/popups/catalog/product/index.vue')
    },
    mixins: [paginationMixin],
    data() {
        return {
            fields: [
                {
                    key: 'id',
                    label: 'id'
                },
                {
                    key: 'photo',
                    label: 'Фото',
                },
                {
                    key: 'title',
                    label: 'Название',
                },
                {
                    key: 'model',
                    label: 'Модель',
                },
                {
                    key: 'category',
                    label: 'Категория',
                },
                {
                    key: 'priceList',
                    label: 'Цена (база)',
                },
                {
                    key: 'less_quantity',
                    label: 'Кол-во',
                },
                {
                    key: 'reserved_quantity',
                    label: 'В резерві',
                },
                {
                    key: 'сurrency',
                    label: 'Валюта',
                },
                {
                    key: 'product_status',
                    label: 'Cтатус',
                },
                {
                    key: 'department',
                    label: 'Отдел',
                },
                {
                    key: 'manufacturer',
                    label: 'Производитель',
                },
            ],
            items: [],
            selectedItem: null,
        }
    },
    computed: {
        ...mapGetters({
            menuHide: 'config/menuHide',
            products: 'catalog/products',
            showProductPopup: 'catalog/showProductPopup',

        })
    },
    watch: {
        products(e) {
            this.items = []
            this.createTableItems(e)
            this.res = e
        },
        page(e) {
            this.getProducts({page: e, per_page: this.per_page});
        },
        per_page(e) {
            this.getProducts({page: this.page, per_page: e});
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getProducts({page: 1, per_page: this.per_page}).then(()=>{
            this.toggleLoadingStatus(false);
        })
    },
    methods: {
        getSearchInfo(data){
            if(data.type === 'priceList'){
                data.type = 'sale_price'
            }
            if(this.activeStatus !== 0){
                if(data.query){
                    this.getProducts({[data.type]: data.query,page: this.page, per_page: this.per_page, customer_group_id: this.activeStatus});
                }else{
                    this.getProducts({page: this.page, per_page: this.per_page, customer_group_id: this.activeStatus});
                }
            }else{
                if(data.query){
                    this.getProducts({[data.type]: data.query,page: this.page, per_page: this.per_page});
                }else{
                    this.getProducts({page: this.page, per_page: this.per_page});
                }
            }
        },
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        editClickProduct(item){

            this.showProduct(item.id).then(()=>{
            })
            this.changeShowProductPopup(true);
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        closePopup(){
            this.changeShowProductPopup(false);
        },
        createTableItems(e) {
            this.items = []
            e.data.map(item => {
                this.items.push(
                    {
                        id: item.id,
                        'photo' : item.images,
                        'title' : item && item.title ? item.title : '',
                        'category' : item.category && item.category.title ? item.category.title : "",
                        'priceList' : Number(item.sale_price).toFixed(1),
                        'product_status' : item.status ? 'Вкл' : 'Викл',
                        'department': item.department && item.department ? item.department.title : "",
                        'model' : item.model && item.model.title ? item.model.title : '',
                        'manufacturer' : item.manufacturer && item.manufacturer.title ? item.manufacturer.title : '',
                        'сurrency' : 'грн',
                        'value' : item.current_less_quantity,
                        'valueIn' : item.in_orders_quantity,
                        'less_quantity' : item.less_quantity,
                        'reserved_quantity' : item.reserved_quantity,
                    })
            })
        },
        changeProduct(payload) {
            this.editProduct(payload).then( res => {
                if(res.status){
                    this.getProducts({page: this.page, per_page: this.per_page})
                    this.$toasted.success('Продукт успешно изменен',{
                        duration : 3000
                    });
                    this.changeShowProductPopup(false);
                }else{
                    this.showErrors(res.data.errors);
                }
            }).catch( err => console.log(err))
            // console.log(payload)
        },
        createProduct(payload){
            this.addProduct(payload).then( res => {
                if(res.status){
                    this.changeShowProductPopup(false);
                    this.$toasted.success('Продукт успешно добавлен',{
                        duration : 3000
                    });
                    this.getProducts({page: this.page, per_page: this.per_page})
                }else{
                    this.showErrors(res.data.errors);
                }
            })
        },
        showErrors(e){
            let key;
            for(key in e){
                this.$toasted.error(e[key], {
                    duration: 3000
                });
            }
        },
        removeItem(id){
            console.log('removeItem - ', id)
            this.removeProduct(id).then(res=>{
                console.log('test res - ', res)
                if(res.status){
                    this.items = [];
                    this.getProducts();
                    this.$toasted.success('Продукт успішно видалений',{
                        duration : 3000
                    });
                    this.changeShowProductPopup(false);
                }
            })
        },
        ...mapActions({
            getProducts: 'catalog/getProducts',
            showProduct: 'catalog/showProduct',
            editProduct: 'catalog/editProduct',
            addProduct: 'catalog/addProduct',
            removeProduct: 'catalog/removeProduct',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
            changeShowProductPopup: 'catalog/changeShowProductPopup',
        })
    },
}

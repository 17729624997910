import {mapActions, mapGetters, mapMutations} from 'vuex';
import Header from '@/components/header/index.vue'
import leftBar from '@/components/left-bar/index.vue'
import Table from '@/components/table/list/index.vue'
import TableOptions from '@/components/table/options/index.vue'
import popup from "@/components/popups/contacts/customers/index.vue";
import pagination from '@/components/pagination/index.vue'
import paginationMixin from '@/mixins/pagination/index.js'
import * as moment from "moment/moment";

export default {
    components:{
        Header,
        leftBar,
        Table,
        pagination,
        TableOptions,
        popup,
    },
    mixins: [paginationMixin],
    data(){
        return{
            fields:[
                {
                    key: 'userName',
                    label: 'Имя'
                },
                {
                    key: 'userIp',
                    label: 'ip'
                },
                {
                    key: 'last_activity_at',
                    label: 'останій час активності'
                },
            ],
            items:[], 
            selectedItem: null,
            showPopup: false,
            colOnline: 0,
            colOffline: 0,
        }
    },
    computed: {
        ...mapGetters({
            menuHide:'config/menuHide',
            dashboard:'contacts/dashboard',
        })
    },
    watch:{
        dashboard(e){
            this.items = []
            e.online_users.map(item=>{
                this.items.push({
                    'userName': item.name,
                    'userIp': item.away ? '-оффлайн-' : item.ip ,
                    'last_activity_at': moment(item.last_activity_at).format('YYYY-MM-DD HH:mm'),
                    'away': item.away,
                });
                if(item.away){
                    this.colOffline++;
                }else{
                    this.colOnline++;

                }
            })
            this.res = e;
        },
        page(e) {
            this.getDashboard({page: e, perpage: this.perPage});
        },
        perPage(e) {
            this.getDashboard({page: this.page, perpage: e});
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getDashboard().then(()=>{
            this.toggleLoadingStatus(false);
        });
    },
    methods:{
        sendCustomer(form){
            this.addCustomer(form).then(res=>{
                if(res.status){
                    this.getDashboard({page: 1, perpage: this.perPage})
                    this.$toasted.success('Склад успешно добавлен',{
                        duration : 3000
                    });
                }
                this.showPopup = false;
            })
        },
        changeCustomer(form){
            this.editCustomer(form).then(res=>{
                if(res.status){
                    this.getDashboard({page: 1, perpage: this.perPage})
                    this.$toasted.success('Склад успешно изменен',{
                        duration : 3000
                    });
                    this.showPopup = false;
                }
            })
        },
        ...mapActions({
            getDashboard:'contacts/getDashboard',
            showCustomer:'contacts/showCustomer',
            editCustomer:'contacts/editCustomer',
            addCustomer:'contacts/addCustomer',
            removeCustomer:'contacts/removeCustomer',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    }
}

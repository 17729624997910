import Vue from 'vue';
import Router from 'vue-router';
import Store from './store';
import signIn from './pages/signin/index.vue'
import restore from './pages/restore/index.vue'
import PathNotFound from './pages/404/index.vue'
import warehouse from './pages/warehouse/index.vue'
import warehouseProducts from './pages/warehouseProducts/index.vue'
import provisioners from './pages/provisioners/index.vue'
import productCategory from './pages/catalog/productCategory/index.vue'
import priceCategory from './pages/catalog/priceCategory/index.vue'
import department from './pages/catalog/department/index.vue'
import manufacturer from './pages/catalog/manufacturer/index.vue'
import material from './pages/catalog/material/index.vue'
import model from './pages/catalog/model/index.vue'
import products from './pages/catalog/products/index.vue'
import ordersList from './pages/orders/orders-list/index.vue'
import statusOrders from './pages/orders/status-orders/index.vue'
import typeDelivery from './pages/orders/type-delivery/index.vue'
import typeOrders from './pages/orders/type-orders/index.vue'
import site from './pages/catalog/sites/index.vue'
import lot from './pages/lots/index.vue'
import rate from './pages/rate/rate/index.vue'
import movementProducts from './pages/movement-products/index.vue'
import customers from './pages/contacts/customers/index.vue'
import customerGroups from './pages/contacts/customer_groups/index.vue'
import smsTemplates from './pages/templates/sms_templates/index.vue'
import desktop from './pages/contacts/desktop/index.vue'
import users from './pages/contacts/users/index.vue'
import usersGroup from './pages/contacts/users_group/index.vue'
import statsOrders from './pages/statistics/stats-orders/index.vue'
import lackOf from './pages/statistics/lack-of/index.vue'
import plugins from './pages/plugins/index.vue'
import smsList from './pages/mailing/sms-list/index.vue'

import shipmentList from './pages/shipment-products/shipment-list/index.vue'
import registersNovaposhta from './pages/shipment-products/registers-novaposhta/index.vue'

Vue.use(Router);

if (localStorage.getItem('token')){
    Store.state.auth.isAuthentificated = true
} else{
    Store.state.auth.isAuthentificated = false
}
const isAuthentificated = (to, from, next) =>{
    if(Store.state.auth.isAuthentificated){
        next();
    }else{
        next('/')
    }
}
const isNotAuthentificated = (to, from, next) =>{
    if(!Store.state.auth.isAuthentificated){
        next();
    }else{
        next('/desktop')
    }
}
export default new Router({
    mode: 'history',
    routes: [
        {
            path:'/',
            name: 'sign-in',
            component: signIn,
            beforeEnter: isNotAuthentificated,
        },
        {
            path:'/restore',
            name: 'restore',
            component: restore,
            beforeEnter: isNotAuthentificated,
        },
        {
            path: '/warehouse',
            name:'warehouse',
            component: warehouse,
            beforeEnter: isAuthentificated,
        },
        {
            path: '/warehouse-products',
            name:'warehouseProducts',
            component: warehouseProducts,
            beforeEnter: isAuthentificated,
        },
        {
            path: '/provisioners',
            name:'provisioners',
            component: provisioners,
            beforeEnter: isAuthentificated,
        },
        {
          path: '/lots',
          name: 'lots',
          component: lot,
          beforeEnter: isAuthentificated
        },
        {
          path: '/movement-products',
          name: 'movementProducts',
          component: movementProducts,
          beforeEnter: isAuthentificated
        },
        {
            path: '/product-category',
            name:'productCategory',
            component: productCategory,
            beforeEnter: isAuthentificated,
        },
        {
            path: '/price-category',
            name:'priceCategory',
            component: priceCategory,
            beforeEnter: isAuthentificated,
        },
        {
            path: '/department',
            name:'department',
            component: department,
            beforeEnter: isAuthentificated,
        },
        {
            path: '/manufacturer',
            name:'manufacturer',
            component: manufacturer,
            beforeEnter: isAuthentificated,
        },
        {
            path: '/material',
            name:'material',
            component: material,
            beforeEnter: isAuthentificated,
        },
        {
            path: '/model',
            name:'model',
            component: model,
            beforeEnter: isAuthentificated,
        },
        {
            path: '/products',
            name:'products',
            component: products,
            beforeEnter: isAuthentificated,
        },
        {
            path: '/orders-list',
            name:'ordersList',
            component: ordersList,
            beforeEnter: isAuthentificated,
        },
        {
            path: '/status-orders',
            name:'statusOrders',
            component: statusOrders,
            beforeEnter: isAuthentificated,
        },
        {
            path: '/type-delivery',
            name:'typeDelivery',
            component: typeDelivery,
            beforeEnter: isAuthentificated,
        },
        {
            path: '/type-orders',
            name:'typeOrders',
            component: typeOrders,
            beforeEnter: isAuthentificated,
        },
        {
            path: '/sites',
            name: 'sites',
            component: site,
            beforeEnter: isAuthentificated
        },
        {
            path: '/customers',
            name: 'customers',
            component: customers,
            beforeEnter: isAuthentificated
        },
        {
            path: '/desktop',
            name: 'desktop',
            component: desktop,
            beforeEnter: isAuthentificated
        },
        {
            path: '/users',
            name: 'users',
            component: users,
            beforeEnter: isAuthentificated
        },
        {
            path: '/users_group',
            name: 'usersGroup',
            component: usersGroup,
            beforeEnter: isAuthentificated
        },
        {
            path: '/customer-groups',
            name: 'customerGroups',
            component: customerGroups,
            beforeEnter: isAuthentificated
        },
        {
            path: '/sms-templates',
            name: 'smsTemplates',
            component: smsTemplates,
            beforeEnter: isAuthentificated
        },
        {
            path: '/plugins',
            name: 'plugins',
            component: plugins,
            beforeEnter: isAuthentificated
        },
        {
            path: '/shipment-list',
            name: 'shipmentList',
            component: shipmentList,
            beforeEnter: isAuthentificated
        },
        {
            path: '/registers-novaposhta',
            name: 'registersNovaposhta',
            component: registersNovaposhta,
            beforeEnter: isAuthentificated
        },
        {
            path: '/stats_orders',
            name: 'statsOrders',
            component: statsOrders,
            beforeEnter: isAuthentificated
        },
        {
            path: '/lack-of',
            name: 'lackOf',
            component: lackOf,
            beforeEnter: isAuthentificated
        },
        {
            path: '/rate',
            name: 'rate',
            component: rate,
            beforeEnter: isAuthentificated
        },
        {
            path: '/sms-list',
            name: 'smsList',
            component: smsList,
            beforeEnter: isAuthentificated
        },
        {
            path: '/:pathMatch(.*)*',
            component: PathNotFound
        },
    ]
})

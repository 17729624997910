import axios from "axios";

axios.defaults.headers = {
    'Authorization':'Bearer ' + localStorage.getItem('token'),
    'Accept':'application/json',
}

const state = {
    prepareFilters: null,
    reportOrders: null,
};

const getters = {
    //product categories
    prepareFilters: state => state.prepareFilters,
    reportOrders: state => state.reportOrders,
};
const actions = {

    //begin order-status

    getPrepareFilters: async ({commit}, options) =>{
        let url = '/report/prepare_filters'
        if(options) {
            url = `/report/prepare_filters`
        }
        try{
            const result = await axios.get(url);
            commit('changePrepareFilters', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },

    getReportOrders: async ({commit}, payload) =>{
        let url = '/report/get_orders'
        try{
            const result = await axios.post(url, payload);
            commit('changeReportOrders', result.data);
        }catch (e){
            console.log(e)
            throw e
        }
    },

}



const mutations = {
    // status-orders
    changePrepareFilters(state, status){
        state.prepareFilters = status[0]; 
    },
    changeReportOrders(state, status){
        state.reportOrders = [];
        state.reportOrders = status.models;
    },
    changes(){
    }
};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

import { mapGetters, mapMutations, mapActions} from 'vuex';
import * as moment from "moment/moment";

export default {
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            form: {
                name: '',
                comment: '',
            }
        }
    },

    computed: {
        ...mapGetters({
            customer: 'contacts/customer',
            customerGroups: 'contacts/customerGroups',
        }),
    },
    watch:{
        customer(e){
            if(e){
                this.form = e;
            }else{
                this.form = {
                    name: '',
                }
            }
        }
    },
    created() {
        this.getCustomerGroups();
    },
    methods: {
        closePopup() {
            this.$emit('closePopup')
        },
        sendChanges() {
            if(this.customer){
                this.$emit('changeCustomer', this.form)
            }else{
                this.$emit('sendCustomer', this.form)
            }
        },
        createdDate(date){
            return moment(date).format('YYYY-MM-DD HH:mm')
        },
        ...mapActions({
            getCustomerGroups: 'contacts/getCustomerGroups',
        }),
        ...mapMutations({
            changeCustomer: 'contacts/changeCustomer',
        })
    },
    destroyed() {
        this.changeCustomer(null);
    }
}
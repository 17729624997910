import {mapGetters, mapActions, mapMutations} from 'vuex';
import Header from '@/components/header/index.vue'
import leftBar from '@/components/left-bar/index.vue'
import TableOptions from '@/components/table/options/index.vue'
import Table from '@/components/table/list/index.vue'
import productModel from '@/components/popups/catalog/model/index.vue'
import pagination from '@/components/pagination/index.vue'
import paginationMixin from '@/mixins/pagination/index.js'

export default {
    components:{
        Header,
        leftBar,
        TableOptions,
        Table,
        productModel,
        pagination
    },
    mixins: [paginationMixin],
    data(){
        return{
            fields:[
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key:'title',
                    label:'Название',
                },
                {
                    key:'status',
                    label:'Cтатус',
                },
                {
                    key:'sort',
                    label:'Сорт',
                    sortable: true
                },
            ],
            items:[],
            selectedItem: null,
            showModelPopup: null,
        }
    },
    computed:{
        ...mapGetters({
            menuHide:'config/menuHide',
            models:'catalog/models',
        })
    },
    watch:{
        models(e){
            this.items = []
            e.data.map(item=>{
                this.items.push({id:item.id, 'title': item.title, 'status': Boolean(item.status), 'sort': item.sort})
            })
            this.res = e
        },
        page(e) {
            this.getModels({page: e, per_page: this.per_page});
        },
        per_page(e) {
            this.getModels({page: this.page, per_page: e});
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getModels({page: 1, per_page: this.per_page}).then(()=>{
            this.toggleLoadingStatus(false);
        })
    },
    methods:{
        getSearchInfo(data){
            if(data.query){
                this.getModels({[data.type]: data.query,page: this.page, per_page: this.per_page});
            }else{
                this.getModels({page: this.page, per_page: this.per_page});
            }
        },
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        sendModel(item){
            this.addModel(item).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Модель успешно добавлена', {
                        duration: 3000
                    });
                    this.showModelPopup = false;
                    this.getModels();
                }
            });
        },
        changeModel(item){
            this.editModel(item).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Модель успешно обновлена', {
                        duration: 3000
                    });
                    this.showModelPopup = false;
                    this.getModels();
                }
            });
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        toggleStatus(item){
            const payload = {
                id: item.id,
                data:{
                    title:item.title,
                    status:item.status
                }
            }
            this.editModel(payload)

        },
        editClickModel(item){
            this.showModel(item.id)
            this.showModelPopup = true;
        },
        removeItem(id){
            this.removeModel(id).then(res=>{
                if(res.status){
                    this.items = [];
                    this.getModels();
                    this.$toasted.success('Модель успешно удалена',{
                        duration : 3000
                    });
                    this.showModelPopup = false;
                }
            })
        },
        ...mapActions({
            getModels:'catalog/getModels',
            showModel:'catalog/showModel',
            editModel:'catalog/editModel',
            addModel:'catalog/addModel',
            removeModel:'catalog/removeModel',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    }
}

import {mapGetters, mapActions, mapMutations} from 'vuex';
import paginationMixin from '@/mixins/pagination/index.js'

export default {
    components:{
        Header: () => import('@/components/header/index.vue'),
        leftBar: () => import('@/components/left-bar/index.vue'),
        Table: () => import('@/components/table/list/index.vue'),
        pagination: () => import('@/components/pagination/index.vue'),
        sitePopup: () => import('@/components/popups/catalog/site/index.vue'),
        TableOptions: () => import('@/components/table/options/index.vue'),
    },
    mixins: [paginationMixin],
    data() {
        return {
            fields:[
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key:'title',
                    label:'Название',
                },
                {
                    key:'domain',
                    label:'Домен',
                },
                {
                    key:'api_key',
                    label:'API ключ',
                },
            ],
            items: [],
            selectedItem: null,
            showSitePopup: false
        }
    },
    computed:{
        ...mapGetters({
            menuHide:'config/menuHide',
            sites:'catalog/sites',
        })
    },
    watch: {
        sites(e) {
            if(!e) {
                return
            }
            this.items = []
            e.data.map(item => {
                this.items.push({id: item.id, 'title': item.title, 'domain': item.domain, 'api_key': item.api_key })
            })
            this.res = e
        },
        page(e) {
            this.getSites({page: e, per_page: this.per_page});
        },
        per_page(e) {
            this.getSites({page: this.page, per_page: e});
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getSites({page: 1, perpga: this.per_page}).then(()=>{
            this.toggleLoadingStatus(false);
        })
    },
    methods: {
        getSearchInfo(data){
            if(data.query){
                this.getSites({[data.type]: data.query,page: this.page, per_page: this.per_page});
            }else{
                this.getSites({page: this.page, per_page: this.per_page});
            }
        },
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        selectItem(item){
            if(item.length) {
                this.selectedItem = item;
                // }else if (item.length > 1) {
                //     this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        editClickSite(item) {
            this.showSite(item.id)
            this.showSitePopup = true
        },
        removeItem(id) {
            this.removeSite(id).then(res=>{
                if(res.status){
                    this.items = [];
                    this.getSites();
                    this.$toasted.success('Сайт успешно удален',{
                        duration : 3000
                    });
                    this.showSitePopup = false;
                }
            })
        },
        changeSite(payload) {
            this.editSite(payload).then( () => {
                this.getSites({page: this.page, per_page: this.per_page})
                this.$toasted.success('Сайт успешно изменен',{
                    duration : 3000
                });
                this.showSitePopup = false
            }).catch( err => console.log(err))
        },
        createSite(payload) {
            this.addSite(payload).then( () => {
                this.showSitePopup = false
                this.$toasted.success('Сайт успешно добавлен',{
                    duration : 3000
                });
                this.getSites({page: this.page, per_page: this.per_page})
            })
        },
        ...mapActions({
            getSites: 'catalog/getSites',
            showSite: 'catalog/showSite',
            editSite: 'catalog/editSite',
            addSite: 'catalog/addSite',
            removeSite: 'catalog/removeSite',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    }
}

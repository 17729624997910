import {mapGetters, mapActions, mapMutations} from 'vuex';
import paginationMixin from '@/mixins/pagination/index.js'
import * as moment from "moment/moment";

export default {
    components: {
        Header: () => import('@/components/header/index.vue'),
        leftBar: () => import('@/components/left-bar/index.vue'),
        Table: () => import('@/components/table/list/index.vue'),
        pagination: () => import('@/components/pagination/index.vue'),
        TableOptions: () => import('@/components/table/options/index.vue'),
        Popup: () => import( '@/components/popups/movementProducts/index.vue'),
        popupAddProduct: () => import( '@/components/popups/movementProducts/add-product/index.vue')
    },
    mixins: [paginationMixin],
    data() {
        return {
            fields: [
                {
                    key: 'id',
                    label: 'id',
                },
                {
                    key: 'warehouseTo',
                    label: 'Со склада',
                },
                {
                    key: 'warehouseFrom',
                    label: 'В склад',
                },
                {
                    key: 'current_user',
                    label: 'Кто переместил',
                },
                {
                    key: 'created_at',
                    label: 'Создано',
                },
                {
                    key: 'transposition_products',
                    label: 'Товары',
                },
            ],
            items: [],
            showLotPopup: false,
            selectedItem: null,
            newProduct:null,
        }
    },
    computed: {
        ...mapGetters({
            users: 'contacts/users',
            menuHide: 'config/menuHide',
            transpositions: 'lot/transpositions',
            warehouseList: 'warehouse/warehouses',
            showAddProductPopUp:'config/showAddProductPopUp',
        })
    },
    watch: {
        transpositions(e) {
            this.items = []
            e.data.map( item => {
                const currentWarehouseTo = this.warehouseList.data.filter(itemWar=>itemWar.id === item['donor_warehouse_id'])
                const currentWarehouseFrom = this.warehouseList.data.filter(itemWar=>itemWar.id === item['recipient_warehouse_id'])
                const currentUser = this.users.filter(itemUser =>itemUser.id === item['user_id'])
                this.items.push({
                    id: item.id,
                    warehouseTo: currentWarehouseTo[0].title,
                    warehouseFrom: currentWarehouseFrom[0].title,
                    created_at:  moment(item.created_at).format('YYYY-MM-DD HH:mm'),
                    current_user:   currentUser[0] ? currentUser[0].name: null,
                    transposition_products:   item.products,

                })
            })
            this.res = e
        },
        page(e) {
            this.getTranspositions({page: e, per_page: this.per_page});
        },
        per_page(e) {
            console.log('e - ', e);
            this.getTranspositions({page: this.page, per_page: e});
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getWarehouses().then(()=>{
            this.getUsers().then(()=>{
                this.getTranspositions({page: 1, per_page: this.per_page}).then(()=>{
                    this.toggleLoadingStatus(false);
                })
            })
        })
    },
    methods: {
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        editClickLot(item) {
            this.showTransposition(item.id)
            this.showLotPopup = true
        },
        selectItem(item) {
            if(item.length) {
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        createLot(payload) {
            this.addTransposition(payload).then( () => {
                this.showLotPopup = false
                this.$toasted.success('Лот успешно добавлен',{
                    duration : 3000
                });
                this.getTranspositions({page: this.page, per_page: this.per_page})
            })
        },
        changeLot(payload) {
            this.editTransposition(payload).then( () => {
                this.getTranspositions({page: this.page, per_page: this.per_page})
                this.$toasted.success('Лот успешно изменен',{
                    duration : 3000
                });
                this.showLotPopup = false
            })
            this.showLotPopup = false
        },
        removeItem(id) {
            this.removeTransposition(id).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Лот успешно удален',{
                        duration : 3000
                    });
                    this.showLotPopup = false;
                    this.getTranspositions({page: 1, per_page: this.per_page})
                }
            })
        },
        addProduct(product){
            this.newProduct = product;
        },
        ...mapActions({
            getTranspositions: 'lot/getTranspositions',
            showTransposition: 'lot/showTransposition',
            addTransposition: 'lot/addTransposition',
            editTransposition: 'lot/editTransposition',
            removeTransposition: 'lot/removeTransposition',
            getWarehouses: 'warehouse/getWarehouses',
            getUsers: 'contacts/getUsers',

        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    },
}

import {required} from 'vuelidate/lib/validators'
import { mapGetters, mapMutations} from 'vuex';

export default {
    props: ['popupType', 'selectedItem'],
    data() {
        return {
            form: {
                title: '',
            },
        }
    },

    validations: {
        form: {
            title: {
                required
            },
        }
    },
    computed: {
        ...mapGetters({
            department:'catalog/department',
        }),
    },
    watch:{
        department(e){
            if(e){
                this.form.title = e.title;
            }
        }
    }, 
    methods: { 
        closePopup() {
            this.$emit('closePopup')
        },
        sendPriceCategory() {
            if(this.department){
                const payload = {
                    id: this.department.id,
                    data:this.form
                }
                this.$emit('changeDepartment', payload)
            }else{
                this.$emit('sendDepartment', this.form)
            }
        },
        ...mapMutations({
            changeDepartment: 'catalog/changeDepartment'
        }),
    },
    destroyed() {
        this.changeDepartment(null);
    }
}
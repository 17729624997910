import {mapGetters, mapActions} from "vuex";
import * as moment from "moment/moment";

export default {
    components: {
        Header: () => import('@/components/header/index.vue'),
        leftBar: () => import('@/components/left-bar/index.vue'),
    },
    data() {
        return {
            percentDopOrders: 0,
            statusIdCollect: [],
            typeTableShow: false,
            showPopup: false,
            air:{
                allCount:0,
                allCountPrice:0,
                allCountPercent: 0,
            },

            motivation: 0,

            // FILTERS

            productCategories: null,
            productCategoriesId: null,

            products: null,
            productsId: null,

            orderings: null,
            orderingsId: null,

            cancelReason: [
                {
                    id:'0',
                    title:'-Нет значения-'
                },
                {
                    id:'1',
                    title:'Дублирующая заявка'
                },
                {
                    id:'2',
                    title:'Клиент отказался или передумал'
                },
                {
                    id:'3',
                    title:'Клиент не оставлял заявку'
                },
                {
                    id:'4',
                    title:'Некорректный номер телефона'
                },
            ],
            cancelReasonId: 0,
            cancelReasonIdToggle: false,

            orderStatuses: null,
            orderStatusesId: [],
            orderStatusesToggle: false,

            shipmentMethods: null,
            shipmentMethodsId: null,
            shipmentMethodsToggle: false,

            employees: null,
            employeesId: null,
            employeesToggle: false,

            departments: null,
            departmentsId: null,
            departmentsToggle: false,

            paymentMethods: null,
            paymentMethodsId: null,
            paymentMethodsToggle: false,

            utmSource: null,
            utmSourceId: null,
            utmSourceToggle: false,

            utmMedium: null,
            utmMediumId: null,
            utmMediumToggle: false,

            utmTerms: null,
            utmTermsId: null,
            utmTermsToggle: false,

            utmContents: null,
            utmContentsId: null,
            utmContentsToggle: false,

            utmCampaigns: null,
            utmCampaignsId: null,
            utmCampaignsToggle: false,

            date: {
                to: 0,
                from: 0
            },

            //table

            fields: [
                {
                    key: 'id',
                    label: 'id',
                },
                {
                    key: 'order_id',
                    label: 'order_id',
                },
                {
                    key: 'created_at',
                    label: 'Добавлено',
                },
                {
                    key: 'updated_at',
                    label: 'Обновлено',
                },
                {
                    key: 'order_products',
                    label: 'Товар',
                },
                {
                    key: 'upsale_products',
                    label: 'Товар (допродаж)',
                },
                {
                    key: 'price',
                    label: 'Ціна',
                },
                {
                    key: 'price_air',
                    label: 'Повітря',
                },
                {
                    key: 'price_all',
                    label: 'Всього',
                },
                {
                    key: 'order_margin',
                    label: 'Маржа'
                },
                {
                    key: 'shipment_method_id',
                    label: 'Доставка',
                },
                {
                    key: 'shipment_date',
                    label: 'Отправлено',
                },
                {
                    key: 'order_status_id',
                    label: 'Статус',
                },
                {
                    key: 'payment_method_id',
                    label: 'Оплата',
                },
                {
                    key: 'employee_id',
                    label: 'Сотрудник',
                },
                {
                    key: 'department_id',
                    label: 'Отдел',
                },
                {
                    key: 'site',
                    label: 'Источник',
                },
                {
                    key: 'utm_source',
                    label: 'utm_source',
                },
                {
                    key: 'utm_medium',
                    label: 'utm_medium',
                },
                {
                    key: 'utm_terms',
                    label: 'utm_terms',
                },
                {
                    key: 'utm_contents',
                    label: 'utm_contents',
                },
                {
                    key: 'utm_campaigns',
                    label: 'utm_campaigns',
                },
            ],
            productFields: [
                {
                    key: 'product_id',
                    label: 'id'
                },
                {
                    key: 'title',
                    label: 'Название товара'
                },
                {
                    key: 'quantity',
                    label: 'Количество'
                },
                {
                    key: 'price',
                    label: 'Сумма'
                },
            ],
            tableItems: [],
            productItems: [],

            ordersInfo: {
                withoutUpsale: 0,
                withUpsale: 0,
                percentUpsale: 0,
            },
            productsInfo: {
                allLength: 0,
                mainLength: 0,
                dopLength: 0,
                percentUpsale: 0,
            },
            productsInfoPrice: {
                allPrice: 0,
                mainPrice: 0,
                dopPrice: 0,
            },

            series: [],
            chartOptions: {
                chart: {
                    id: 'chart2',
                    type: 'line',
                    stacked: true,
                    height: 30,
                    toolbar: {
                        show: false
                    }
                },
                stroke: {
                    width: [2, 1, 1, 1]
                },
                dataLabels: {
                    enabled: false
                },
                fill: {
                    opacity: 1,
                },
                markers: {
                    size: 0
                },
                legend: {
                    position: 'right',
                    horizontalAlign: 'center',
                    offsetY: 70,
                    offsetX: -10,
                },
                xaxis: {
                    categories: ['02:00', '04:00', '08:00', '12:00', '14:00', '16:00', '18:00', '20:00', '23:59'],
                },
                noData: {
                    text: 'Loading...'
                }
            },
            chartOptionsLine: {
                chart: {
                    id: 'chart1',
                    height: 100,
                    type: 'area',
                    brush: {
                        target: 'chart2',
                        enabled: true
                    },
                    selection: {
                        enabled: true,
                        xaxis: {
                            min: 1,
                            max: 15,
                        }
                    },
                },
                fill: {
                    type: 'gradient',
                    gradient: {
                        opacityFrom: 0.91,
                        opacityTo: 0.8,
                    }
                },
                xaxis: {
                    categories: ['02:00', '04:00', '08:00', '12:00', '14:00', '16:00', '18:00', '20:00', '23:59'],
                    tooltip: {
                        enabled: false
                    }
                },
                yaxis: {
                    tickAmount: 1
                },
                legend: {
                    show: false
                },
                noData: {
                    text: 'Loading...'
                }
            },
            timeDay: ['02:00', '04:00', '08:00', '12:00', '14:00', '16:00', '18:00', '20:00', '23:59'],
            timeDate: [],
            activeTimeDayZone: 0,

            // carts
            allChartLine: [],
            chartLine: [],

            margin: 0,
            marginInfo: {
                margin: 0,
                mainPrice: 0,
                dopPrice: 0,
            }, 

        }
    },
    computed: {
        ...mapGetters({
            menuHide: 'config/menuHide',
            prepareFilters: 'statistics/prepareFilters',
            reportOrders: 'statistics/reportOrders',
            orderStatusList: 'orders/orderStatusList',
        }),
    },
    watch: {
        prepareFilters(e) {
            if (e) {

                this.date = {
                    from: moment(e.min_created_from).format('YYYY-MM-DD'),
                    to: moment(e.max_created_from).format('YYYY-MM-DD'),
                };
                let key;
                this.productCategories = [];
                for (key in e.product_categories) {
                    this.productCategories.unshift({
                        id: key,
                        title: e.product_categories[key]
                    })
                }
                this.productCategoriesId = this.productCategories[0].id;

                this.products = [];
                for (key in e.products) {
                    this.products.unshift({
                        id: key,
                        title: e.products[key]
                    })
                }
                this.productsId = this.products[0].id;

                this.orderings = [];
                for (key in e.orderings) {
                    this.orderings.unshift({
                        id: key,
                        title: e.orderings[key]
                    })
                }
                this.orderingsId = this.orderings[0].id;

                // this.cancelReason = [];
                // for (key in e.cancel_reasons) {
                //     this.cancelReason.unshift({
                //         id: key,
                //         title: e.cancel_reasons[key]
                //     })
                // }
                // this.cancelReasonId = this.cancelReason[0].id;

                this.orderStatuses = [];
                for (key in e.order_statuses) {
                    this.orderStatuses.unshift({
                        id: key,
                        title: e.order_statuses[key]
                    })
                }

                this.shipmentMethods = [];
                for (key in e.shipment_methods) {
                    this.shipmentMethods.unshift({
                        id: key,
                        title: e.shipment_methods[key]
                    })
                }
                this.shipmentMethodsId = this.shipmentMethods[0].id;

                this.employees = [];
                for (key in e.employees) {
                    this.employees.unshift({
                        id: key,
                        title: e.employees[key]
                    })
                }
                this.employeesId = this.employees[0].id;

                this.departments = [];
                for (key in e.departments) {
                    this.departments.unshift({
                        id: key,
                        title: e.departments[key]
                    })
                }
                this.departmentsId = this.departments[0].id;

                this.paymentMethods = [];
                for (key in e.payment_methods) {
                    this.paymentMethods.unshift({
                        id: key,
                        title: e.payment_methods[key]
                    })
                }
                this.paymentMethodsId = this.paymentMethods[0].id;

                this.utmSource = [];
                for (key in e.utm_sources) {
                    this.utmSource.unshift({
                        id: key,
                        title: e.utm_sources[key]
                    })
                }
                this.utmSourceId = this.utmSource[0].id;

                this.utmMedium = [];
                for (key in e.utm_mediums) {
                    this.utmMedium.unshift({
                        id: key,
                        title: e.utm_mediums[key]
                    })
                }
                this.utmMediumId = this.utmMedium[0].id;

                this.utmTerms = [];
                for (key in e.utm_terms) {
                    this.utmTerms.unshift({
                        id: key,
                        title: e.utm_terms[key]
                    })
                }
                this.utmTermsId = this.utmTerms[0].id;

                this.utmContents = [];
                for (key in e.utm_contents) {
                    this.utmContents.unshift({
                        id: key,
                        title: e.utm_contents[key]
                    })
                }
                this.utmContentsId = this.utmContents[0].id;

                this.utmCampaigns = [];
                for (key in e.utm_campaigns) {
                    this.utmCampaigns.unshift({
                        id: key,
                        title: e.utm_campaigns[key]
                    })
                }
                this.utmCampaignsId = this.utmCampaigns[0].id;
            }
        },
        reportOrders(e) {
            this.marginInfo.margin = 0;
            this.marginInfo.dopPrice = 0;
            this.marginInfo.mainPrice = 0;
            this.tableItems = [];
            this.productItems = [];

            this.air = {
                allCount:0,
                allCountPrice:0,
                allCountPercent: 0,
            },
            this.percentDopOrders = 0;
            console.log('report order - ', e.filter(item => item.id === 13363));
            if (e) {
                e.map(item => {
                    const shipment = this.shipmentMethods.filter(itemShipment => Number(itemShipment.id) === Number(item.shipment_method_id));
                    const orderStatus = this.orderStatuses.filter(itemStatus => Number(itemStatus.id) === Number(item.order_status_id))
                    const payment = this.paymentMethods.filter(itemStatus => Number(itemStatus.id) === Number(item.payment_method_id))
                    const employees = this.employees.filter(itemStatus => Number(itemStatus.id) === Number(item.employee_id))
                    const departments = this.departments.filter(itemStatus => Number(itemStatus.id) === Number(item.department_id))
                    let haveIsUpsell = false;
                    // let haveAir = false;
                    let allProductPrice = 0;
                    let allProductPriceAir = 0;
                    let orderMargin = 0;

                    if(item.id === 13363) console.log('item - ', item);
                    item.order_products.map(productItem => {
                        allProductPrice += productItem.price * productItem.quantity
                        allProductPriceAir += Number(productItem.price_air) * productItem.quantity
                        this.air.allCount += Number(productItem.price_air) * productItem.quantity
                        orderMargin += productItem.lot_product && productItem.quantity * productItem.price - productItem.quantity * productItem.lot_product.buy_price
                    })
                    this.air.allCountPercent = (this.productsInfoPrice.allPrice * 100) * this.air.allCountPercent
                    this.tableItems.push({
                        id: item.id,
                        order_id: item.order_id,
                        created_at: moment(item.created_at).format('YYYY-MM-DD HH-mm-ss'),
                        updated_at: moment(item.updated_at).format('YYYY-MM-DD HH-mm-ss'),
                        order_products: item.order_products.filter(item => !item.is_upsell),
                        upsale_products:  item.order_products.filter(item => item.is_upsell),
                        price: allProductPrice.toFixed(2),
                        price_air: allProductPriceAir.toFixed(2),
                        price_all: (allProductPrice + allProductPriceAir).toFixed(2),
                        order_margin: orderMargin,
                        shipment_method_id: shipment[0] ? shipment[0].title : null,
                        shipment_date: item.shipment_date ? moment(item.shipment_date).format('YYYY-MM-DD HH-mm-ss') : null,
                        order_status_id: orderStatus[0] ? orderStatus[0].title : null,
                        payment_method_id: payment[0] ? payment[0].title : null,
                        employee_id: employees[0] ? employees[0].title : null,
                        department_id: departments[0] ? departments[0].title : null,
                        site: item.site,
                        utm_source: item.utm_source,
                        utm_medium: item.utm_medium,
                        utm_terms: item.utm_terms,
                        utm_contents: item.utm_contents,
                        utm_campaigns: item.utm_campaigns,
                    })
                    if (item.order_products) {
                        item.order_products.map(productItem => { 
                            this.productItems.push(productItem); 
                            if(productItem.is_upsell !== 0 && !haveIsUpsell){
                                this.percentDopOrders++
                                haveIsUpsell = true
                            }
                        })
                    }


                    // show charts
                    const parseTime = timeString => moment(timeString, 'HH:mm')
                    const parseDay = dateString => moment(dateString, 'DD.MM.YYYY')

                    if (this.date.to === this.date.from) {
                        let createdTime = moment(item.created_at,).format('HH:mm');
                        createdTime = parseTime(createdTime)
                        this.getChartLine(item, createdTime, parseTime, true);
                        this.getAllChartLine(createdTime, parseTime, true);
                    }else{
                        let createdTime = moment(item.created_at,).format('DD.MM.YYYY');
                        this.getDateArray();
                        this.getChartLine(item, createdTime, parseDay, false);
                        this.getAllChartLine(createdTime, parseDay, false);
                    }

                    this.getMargin(item);



                })



                let allInfo = {
                    allLength: 0,
                    allPrice: 0,
                    dopPrice: 0,
                    mainLength: 0,
                    mainPrice: 0,
                    dopLength: 0,
                    motivation: 0,
                    orderWithoutUpsale: 0,
                    orderWithUpsale: 0,
                }
                this.productItems.map((item) => {
                    allInfo.allLength += Number(item.quantity)
                    allInfo.allPrice += Number(item.quantity) * Number(item.price);
                    if (item.is_upsell !== 1) {
                        allInfo.mainLength += Number(item.quantity)
                        allInfo.mainPrice += Number(item.quantity) * Number(item.price);
                    } else {
                        allInfo.dopLength += item.quantity
                        allInfo.dopPrice += Number(item.quantity) * Number(item.price);
                        allInfo.motivation += Number(item.product.upsell_manager_motivation) * Number(item.quantity);
                    }
                })
                this.productsInfoPrice = {
                    allPrice: allInfo.allPrice,
                    mainPrice: allInfo.mainPrice,
                    dopPrice: allInfo.dopPrice,
                }
                this.productsInfo = {
                    allLength: allInfo.allLength,
                    mainLength: allInfo.mainLength,
                    dopLength: allInfo.dopLength,
                    percentUpsale:((allInfo.dopLength * 100) / allInfo.allLength).toFixed(2)
                }
                this.motivation = allInfo.motivation

                this.tableItems.map(item => {
                    if(item.upsale_products.length){
                        allInfo.orderWithUpsale += 1;
                    }else{
                        allInfo.orderWithoutUpsale += 1;
                    }
                })
                this.ordersInfo = {
                    withoutUpsale: allInfo.orderWithoutUpsale,
                    withUpsale: allInfo.orderWithUpsale,
                    percentUpsale:((allInfo.orderWithUpsale * 100) / this.tableItems.length).toFixed(2)
                }
            }


        }
    },
    created() {
        this.getOrderStatuses().then(() => {
            this.getPrepareFilters().then(() => {
                this.getReports()
            });
        })

    },
    methods: {
        percentDopOrdersResult(col){
            const percent = 100 / this.tableItems.length;
            const result = percent * col;
            return Number(result).toFixed(2);
        },
        getMargin(productItem){
            productItem.order_products.map(item=>{
                if(item.lot_product){
                    this.marginInfo.margin += item.price * item.quantity - item.lot_product.buy_price * item.quantity
                    if(item.is_upsell){
                        this.marginInfo.dopPrice  += item.price * item.quantity - item.lot_product.buy_price * item.quantity
                    }else{
                        this.marginInfo.mainPrice  += item.price * item.quantity - item.lot_product.buy_price * item.quantity
                    }
                } 
            })
        },
        getDateArray(){
            const timeDate = [];
            this.timeDate = [];
            let dateTo = this.$moment(this.date.to);
            let dateFrom = this.$moment(this.date.from);
            const dayUse = dateTo.diff(dateFrom,'day');
            for(let i = 0; i <= dayUse; i++){
                timeDate.push(moment(dateFrom, "DD.MM.YYYY").add('day', i))
            }
            timeDate.map(timeDateItem=>{
                const day = timeDateItem.format('DD');
                const month = timeDateItem.format('MM');
                const year = timeDateItem.format('YYYY');
                this.timeDate.push(day + '.' + month + '.' + year);
            })
        },
        getChartLine(item, createdTime, parseTime, oneDay) {
            const orderStatus = this.orderStatusList.data.filter(statusItem => statusItem.id === item.order_status_id)
            if (!this.chartLine.length) {
                this.statusIdCollect.push(item.order_status_id)
                this.addToChartLine(orderStatus[0], item, createdTime, parseTime, oneDay)
            } else {
                if (!this.statusIdCollect.includes(item.order_status_id)) {
                    this.statusIdCollect.push(item.order_status_id)
                    this.addToChartLine(orderStatus[0], item, createdTime, parseTime, oneDay)
                }else{
                    this.addToOtherChartLine(orderStatus[0], item, createdTime, parseTime, oneDay)
                }
            }
        },
        addToChartLine(orderStatus, item, createdTime, parseTime, oneDay){
            this.chartLine.push({
                name: orderStatus.title,
                type: 'column',
                id: item.order_status_id,
                color: orderStatus.color,
                data: this.addFirstData(item, createdTime, parseTime, null, oneDay)
            })
        },
        addToOtherChartLine(orderStatus, item, createdTime, parseTime, oneDay){
            this.chartLine.map(chartItem =>{
                if(chartItem.id === item.order_status_id){
                    chartItem.data = this.addFirstData(item, createdTime, parseTime, chartItem.data, oneDay)
                }
            })
        },
        addFirstData(item, createdTime, parseTime, prevData, oneDay) {
            let dataArray = [];
            let firstResult = false
            const timeArray = oneDay ? this.timeDay : this.timeDate
            timeArray.map((time, index) => {
                createdTime = moment(createdTime, 'DD.MM.YYYY');
                time = parseTime(time)
                if (parseTime(time).isAfter(createdTime) && !firstResult) {
                    dataArray[index] = prevData ? prevData[index] + 1 : 1
                    firstResult = true;
                } else if(parseTime(time).diff(createdTime) === 0){
                    dataArray[index] = prevData ? prevData[index] + 1 : 1
                    firstResult = true;
                }else {
                    dataArray[index] = prevData ? prevData[index] : 0
                }
            })
            return dataArray;
        },
        chartCategories(){
            if(this.date){
                   this.chartOptions = {
                       xaxis: {
                           categories: this.date.to === this.date.from ? this.timeDay : this.timeDate
                       },
                       chart: {
                           selection: {
                               xaxis: {
                                   max: this.date.to === this.date.from ? this.timeDay.length : this.timeDate.length + 1,
                               }
                           },
                       },
                   }
                   this.chartOptionsLine = {
                       xaxis: {
                           categories: this.date.to === this.date.from ? this.timeDay : this.timeDate
                       },
                       chart: {
                           selection: {
                               xaxis: {
                                   max: this.date.to === this.date.from ? this.timeDay.length : this.timeDate.length + 1,
                               }
                           },
                       },
                   }
            }

        },
        update(){
            this.chartCategories();
            this.chartLine.push({
                name: 'Все',
                type: 'line',
                color: '#000',
                data: this.allChartLine
            })
            this.series = this.chartLine;
        },
        getAllChartLine(createdTime, parseTime, oneDay) {
            let firstResult = false
            const timeArray = oneDay ? this.timeDay : this.timeDate
            timeArray.map((time, index) => {
                createdTime = moment(createdTime, 'DD.MM.YYYY');
            time = parseTime(time)
                if (parseTime(time).isAfter(createdTime) && !firstResult) {
                    this.allChartLine[index] = !this.allChartLine[index] ? 1 : Number(this.allChartLine[index]) + 1;
                    firstResult = true;
                } else if(parseTime(time).diff(createdTime) === 0){
                    this.allChartLine[index] = !this.allChartLine[index] ? 1 : Number(this.allChartLine[index]) + 1;
                    firstResult = true;
                } else if (!this.allChartLine[index]) {
                    this.allChartLine[index] = this.allChartLine[index] ? this.allChartLine[index] : 0;
                }
            })
        },
        reloadProductInfo() {
            this.motivation = 0;
            this.productsInfo = {
                allLength: 0,
                mainLength: 0,
                dopLength: 0,
            }
            this.productsInfoPrice = {
                allPrice: 0,
                mainPrice: 0,
                dopPrice: 0,
            }
        },
        percentDopSell(main, dop) {
            let result;
            if (main > 0 && dop > 0) {
                let onePercent = main / 100;
                result = ((dop / onePercent) + 100).toFixed(2)
            } else if (main <= 0 && dop > 0) {
                result = 100
            } else {
                result = 0
            }
            return !isNaN(result) ? result : 0
        },
        mediumPrice(count, length) {
            const result = count / length
            return !isNaN(result) ? Number(result).toFixed(2) : 0
        },
        getReports() {
            this.reloadProductInfo();
            this.chartLine = [];
            this.allChartLine = [];
            this.statusIdCollect = [];
            const payload = {
                created_from: this.date.from,
                created_to: this.date.to,
                product_category_id: this.productCategoriesId,
                product_id: this.productsId,
                order_by: this.orderingsId,
                order_statuses: this.orderStatusesToggle ? this.orderStatusesId : null,
                shipment_method_id: this.shipmentMethodsToggle ? this.shipmentMethodsId : null,
                employee_id: this.employeesToggle ? this.employeesId : null,
                department_id: this.departmentsToggle ? this.departmentsId : null,
                payment_method_id: this.paymentMethodsToggle ? this.paymentMethodsId : null,
                utm_source: this.utmSourceToggle ? this.utmSourceId : null,
                utm_medium: this.utmMediumToggle ? this.utmMediumId : null,
                utm_term: this.utmTermsToggle ? this.utmTermsId : null,
                utm_content: this.utmContentsToggle ? this.utmContentsId : null,
                utm_campaign: this.utmCampaignsToggle ? this.utmCampaignsId : null,
                cancel_reason: this.cancelReasonId ? this.cancelReasonId : null,
            }
            this.getReportOrders(payload).then(()=>{
                setTimeout(()=>{
                    this.update();
                },1000)
            });
        },
        getLogicCost(){
            if(this.reportOrders){
                const costArray = []
                let cancelCost = 0;
                this.reportOrders.map(item=>{
                    if(item.order_status_id === 30 && item.shipment_data && item.shipment_data.DocumentCost !== undefined){
                        costArray.push(item.shipment_data)
                        cancelCost += (+item.shipment_data.DocumentCost * 2) ;
                    }else if(item.shipment_data && item.shipment_data.DocumentCost !== undefined && item.shipment_data.PayerType === "Sender"){
                        costArray.push(item.shipment_data)
                        cancelCost += (+item.shipment_data.DocumentCost) ;
                    }
                })
                return cancelCost
            }else{
                return 0;
            }
        },
        ...mapActions({
            getPrepareFilters: 'statistics/getPrepareFilters',
            getReportOrders: 'statistics/getReportOrders',
            getOrderStatuses: 'orders/getOrderStatuses'
        }),
    }
}
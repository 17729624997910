import {mapActions, mapGetters, mapMutations} from 'vuex';
import Header from '@/components/header/index.vue'
import leftBar from '@/components/left-bar/index.vue'
import Table from '@/components/table/list/index.vue'
import TableOptions from '@/components/table/options/index.vue'
import popup from "@/components/popups/contacts/customerGroups/index.vue";
import pagination from '@/components/pagination/index.vue'
import paginationMixin from '@/mixins/pagination/index.js'

export default {
    components:{
        Header,
        leftBar,
        Table,
        pagination,
        TableOptions,
        popup,
    },
    mixins: [paginationMixin],
    data(){
        return{
            fields:[
                {
                    key: 'id',
                    label: 'id'
                },
                {
                    key: 'customer-group',
                    label: 'Название'
                },
                {
                    key: 'description',
                    label: 'Описание'
                },
                {
                    key: 'sort',
                    label: 'sort'
                },
            ],
            items:[],
            showPopup: false,
            selectedItem: null,
        }
    },
    computed: {
        ...mapGetters({
            menuHide:'config/menuHide',
            customerGroups:'contacts/customerGroups',
        })
    },
    watch:{
        customerGroups(e){
            this.items = []
            e.data.map(item=>{
                this.items.push({
                    id:item.id,
                    'customer-group': item.title,
                    description: item.description,
                    'sort': item.sort,
                });
            })
            this.res = e
        },
        page(e) {
            this.getCustomerGroups({page: e, perpage: this.perPage});
        },
        perPage(e) {
            this.getCustomerGroups({page: this.page, perpage: e});
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getCustomerGroups({page: 1, perpage: this.perPage}).then(()=>{
            this.toggleLoadingStatus(false);
        });
    },
    methods:{
        getSearchInfo(data){
            if(data.query){
                this.getCustomerGroups({[data.type]: data.query});
            }else{
                this.getCustomerGroups();
            }
        },
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        changePopup(item){
            this.showCustomerGroup(item.id)
            this.showPopup = true;
        },
        editClickWarehouse(item){
            this.showCustomerGroup(item.id)
            this.showPopup = true;
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        sendCustomerGroup(form){
            this.addCustomerGroup(form).then(res=>{
                if(res.status){
                    this.getCustomerGroups();
                    this.$toasted.success('Группа успешно добавлена',{
                        duration : 3000
                    });
                }
                this.showPopup = false;
            })
        },
        changeCustomerGroup(form){
            this.editCustomerGroup(form).then(res=>{
                if(res.status){
                    this.getCustomerGroups();
                    this.$toasted.success('Группа успешно изменена',{
                        duration : 3000
                    });
                    this.showPopup = false;
                }
            })
        },
        removeItem(id){
          this.removeCustomerGroup(id).then(res=>{
              if(res.status){
                  this.getCustomerGroups();
                  this.$toasted.success('Группа успешно удален',{
                      duration : 3000
                  });
                  this.showPopup = false;
              }
          })
        },
        ...mapActions({
            getCustomerGroups:'contacts/getCustomerGroups',
            showCustomerGroup:'contacts/showCustomerGroup',
            editCustomerGroup:'contacts/editCustomerGroup',
            addCustomerGroup:'contacts/addCustomerGroup',
            removeCustomerGroup:'contacts/removeCustomerGroup',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    }
}

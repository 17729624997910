import {mapActions, mapGetters, mapMutations} from "vuex";

export default {
    components:{
        Header: ()=> import('@/components/header/index.vue'),
        leftBar: ()=> import('@/components/left-bar/index.vue'),
        TableOptions: ()=> import('@/components/table/options/index.vue'),
        Table: ()=> import('@/components/table/list/index.vue'),
        registerTtnPopup: ()=> import('@/components/popups/confirmRegisterTtn/index.vue'),
        changeStatusPopup: ()=> import('@/components/popups/changeStatus/index.vue')
    },
    data(){
       return{
           checkedAll: false,
           checkoutList: [],
           declarations:{
               with:0,
               without:0
           },
           filterList: {
               department_id: null,
               payment_method_id: null,
               shipment_method_id: null,
               order_status_id: 8,
           },
           fields:[
               {
                   key:'shipment_id',
                   label:'id заказа',
               },
               {
                   key:'shipment_type',
                   label:'Служба доставки',
               },
               {
                   key:'shipment_address',
                   label:'Адрес ',
               },
               {
                   key:'shipment_recipient',
                   label:'Получатель',
               },
               {
                   key:'shipment_products',
                   label:'Товар',
               },
               {
                   key:'shipment_count',
                   label:'Сумма',
               },
               {
                   key:'shipment_created_at',
                   label:'Добавлено',
               },
               {
                   key:'shipment_department',
                   label:'Отдел',
               },
               {
                   key:'shipment_payment_type',
                   label:'Способ платы',
               },
               {
                   key:'shipment_description',
                   label:'Комментарий',
               },
           ],
           items:[],
           fieldsDemand:[
               {
                   key:'id',
                   label:'№'
               },
               {
                   key:'name',
                   label:'Наименование'
               },
               {
                   key:'demand_count',
                   label:'Колличество'
               },
           ],
           itemsDemand:[],
           hasCheckTtn: false,
           showRegisterTtn: false,
           showChangeStatusPopup: false
       }
    },
    computed:{
      ...mapGetters({
          menuHide:'config/menuHide',
          ordersList:'orders/ordersList',
          departments:'catalog/departments',
          registryList:'orders/registryList',
          paymentMethods:'orders/paymentMethods',
          shipmentMethods:'orders/shipmentMethods',
      })
    },
    watch:{
        ordersList(e){
            const list = e.data;
            this.filterItems(list)
            this.declarations = {
                with:0,
                without:0,
            }
            list.map(item=>{
                if(item.ttn){
                    this.declarations.with++
                }else{
                    this.declarations.without++
                }
            })
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getShipmentMethods().then(()=>{
            this.getDepartments().then(()=>{
                this.getPaymentMethods().then(()=>{
                    this.getOrders({order_status_id: 8}).then(()=>{
                        this.toggleLoadingStatus(false);
                    })
                })
            })
        })
    },
    methods:{
        changeStatus(statusId){
            let idList = [];
            this.checkoutList.map(item=>{
                idList.push(item.shipment_id);
            })
            let payloadChangeList = {
                'orders_id': idList,
                'order_status_id': statusId
            }
            this.toggleLoadingStatus(true);
            this.changeOrdersStatus(payloadChangeList).then(res=>{
                if(res.status){
                    this.getOrders({order_status_id: 8}).then(()=>{
                        this.toggleLoadingStatus(false);
                        this.$toasted.success('Статус успешно изменен',{
                            duration: 3000
                        })
                    })
                }
            })
        },
        createNewRegister(){
            const ttnList = [];
            this.checkoutList.map(item =>{
                if(item.shipment_ttn){
                    ttnList.push(item.shipment_ttn);
                }
            })
            this.createRegistry({ttn:ttnList}).then(res=>{
                if(res.status){
                    this.$toasted.success('Реестр успешно добавлен',{
                        duration : 3000
                    });
                }else {
                    this.$toasted.error('Произошла ошибка',{
                        duration : 3000
                    });
                }
            })
        },
        addToRegister(){
            const ttnList = [];
            this.checkoutList.map(item =>{
                if(item.shipment_ttn){
                    ttnList.push(item.shipment_ttn);
                }
            })
            this.createRegistry({ttn:ttnList, registry_id: this.registryList.models[0].Ref }).then(res=>{
                if(res.status){
                    this.$toasted.success('Реестр успешно добавлен',{
                        duration : 3000
                    });
                }else {
                    this.$toasted.error('Произошла ошибка',{
                        duration : 3000
                    });
                }
            })

        },
        checkAllItems(){
            this.checkoutList = [];
            this.items.map(item=>{
                if(this.checkedAll){
                    item.shipment_checkout = true;
                    this.checkoutList.push(item);
                }else{
                    item.shipment_checkout = false;
                }
            })
            this.checkTtnButton();
        },
        changeItemStatus(payload){
            let itemResult = this.items.filter(item => item.shipment_id === payload.id)
            itemResult = itemResult[0];
            if(payload.status === true){
                this.checkoutList.push(itemResult)
            }else if(payload.status === 'not_accepted' || payload.status === false){
                const index = this.checkoutList.indexOf(itemResult);
                this.checkoutList.splice(index, 1)
            }
            this.checkTtnButton();
        },
        checkTtnButton(){
            this.hasCheckTtn = false;
            this.checkoutList.map(item=>{
                if(item.shipment_ttn){
                    this.hasCheckTtn = true
                }
            })
        },
        filterItems(e){
            this.items = [];
            this.itemsDemand = [];
            e.map((item, index)=>{
                let price = 0;
                item.order_products.map(itemProduct=>{
                    price += itemProduct.price * itemProduct.quantity
                    if(!this.itemsDemand.some(it => it.product_id === itemProduct.product_id)){
                        this.itemsDemand.push({
                            id: index +1,
                            product_id: itemProduct.product_id,
                            name: itemProduct.product_id +' - '+ itemProduct.title,
                            demand_count: itemProduct.quantity
                        })
                    }else{
                        this.itemsDemand.map(it =>{
                            if(it.product_id === itemProduct.product_id){
                                it.demand_count += itemProduct.quantity
                            }
                        })
                    }
                })
                let shipmentMethods = null;
                let department = null;
                let paymentMethods = null;
                if(this.shipmentMethods && this.shipmentMethods.data){
                    shipmentMethods = this.shipmentMethods.data.filter(itemShipment=> itemShipment.id === item.shipment_method_id)
                    shipmentMethods = shipmentMethods[0];
                }

                if(this.departments && this.departments.data) {
                    department = this.departments.data.filter(itemShipment => itemShipment.id === item.department_id)
                    department = department[0];
                }
                if(this.paymentMethods && this.paymentMethods.data) {
                    paymentMethods = this.paymentMethods.data.filter(itemShipment => itemShipment.id === item.payment_method_id)
                    paymentMethods = paymentMethods[0];
                }
                this.items.push({
                    shipment_id: item.id ? item.id : null,
                    shipment_type: shipmentMethods ? shipmentMethods.title : null,
                    shipment_address: item.address ? item.address : null,
                    shipment_recipient: item.customer_name && item.phone ? [{name:item.phone},{ name:item.customer_name}] : null,
                    shipment_products: item.order_products ? item.order_products : null,
                    shipment_created_at: item.created_at ? this.$moment(item.created_at).format('DD.MM.YYYY HH:MM:ss') : null,
                    shipment_department: department ? department.title : null,
                    shipment_payment_type: paymentMethods ? paymentMethods.title : null,
                    shipment_description: item.manager_comment ? item.manager_comment : null,
                    shipment_count: Number(price).toFixed(2),
                    shipment_ttn: item.ttn ? item.ttn : null,
                    shipment_checkout: false,
                })
            })
        },
        changeFilter(){
            console.log('this.filterList - ', this.filterList);
            const payload = {};
            let key;
            for(key in this.filterList){
                if(this.filterList[key]){
                    payload[key] = this.filterList[key]
                }
            }
            console.log('payload - ', payload);
            this.getOrders(payload)
        },
        showOnlyWithDeclaration(){
          let list = [];
          this.ordersList.data.map(item=>{
              if(item.ttn){
                  list.push(item);
              }
          })
            this.filterItems(list);
        },
        showOnlyWithoutDeclaration(){
          let list = [];
          this.ordersList.data.map(item=>{
              if(!item.ttn){
                  list.push(item);
              }
          })
            this.filterItems(list);
        },
        showAllDeclaration(){
            this.filterItems(this.ordersList.data);
        },
        ...mapActions({
            getOrders:'orders/getOrders',
            getShipmentMethods:'orders/getShipmentMethods',
            getDepartments: 'catalog/getDepartments',
            createRegistry: 'catalog/createRegistry',
            getPaymentMethods:'orders/getPaymentMethods',
            changeOrdersStatus: 'orders/changeOrdersStatus',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        }),
    }
}
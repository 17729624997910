import axios from 'axios';
import qs from 'qs';

axios.defaults.headers = {
    'Authorization':'Bearer ' + localStorage.getItem('token'),
    'Accept':'application/json',
}

const state = {
    customers: null,
    customer: null,
    customerGroups: null,
    customerGroup: null,
    dashboard: null,
    users: null,
    user: null,
    usersGroup: null,
    userGroup: null,
};

const getters ={
    customers: state => state.customers,
    customer: state => state.customer,
    customerGroups: state => state.customerGroups,
    customerGroup: state => state.customerGroup,
    dashboard: state => state.dashboard,
    users: state => state.users,
    user: state => state.user,
    usersGroup: state => state.usersGroup,
    userGroup: state => state.userGroup,
};

const actions = {
    getCustomers: async({commit}, options) => {
        try {
            const result = await axios.get('/customers',{
                params: options,
                paramsSerializer: (params) =>{
                    return qs.stringify(params);
                }
            })
            commit('changeCustomers', result.data.models)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    showCustomer: async({commit}, id) => {
        try{
            const result = await axios.get('/customers/'+id);
            commit('changeCustomer', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    editCustomer: async ({commit}, payload) =>{
        try{
            const result = await axios.put('/customers/'+payload.id, payload);
            commit('changes', result.data.model);
            return {
                status: true,
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    addCustomer: async ({commit}, payload) =>{
        try{
            const result = await axios.post('/customers', payload);
            commit('changes', result.data.model);
            return {
                status: true,
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    removeCustomer: async ({commit},id) =>{
        try{
            const result = await axios.post('/customers/'+id,{
                "_method" : "DELETE"
            });
            commit('changes', result.data.model);
            return {
                status: true,
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    getCustomerGroups: async({commit}, options) => {
        try {
            const result = await axios.get('/customer_groups',{
                params: options,
                paramsSerializer: (params) =>{
                    return qs.stringify(params);
                }
            })
            commit('changeCustomerGroups', result.data.models)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    showCustomerGroup: async({commit}, id) => {
        try{
            const result = await axios.get('/customer_groups/'+id);
            commit('changeCustomerGroup', result.data.models);
        }catch (e){
            console.log(e)
            throw e
        }
    },
    editCustomerGroup: async (ctx, payload) =>{
        try{
             await axios.put('/customer_groups/'+payload.id, payload);
            return {
                status: true,
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    addCustomerGroup: async (ctx, payload) =>{
        try{
              await axios.post('/customer_groups', payload); 
            return {
                status: true,
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    removeCustomerGroup: async ({commit},id) =>{
        try{
            const result = await axios.post('/customer_groups/'+id,{
                "_method" : "DELETE"
            });
            commit('changes', result.data.model);
            return {
                status: true,
            }
        }catch (e){
            if(e.response.status === 302){
                return {
                    status: true,
                }
            }else{
                throw e
            }
        }
    },
    getDashboard: async({commit}) => {
        try {
            const result = await axios.get('/dashboard/get_data');
            commit('changeDashboard', result.data.models[0])
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    getUsers: async ({commit}, options) => {
        try {
            const result = await axios.get('/users',{
                params: options,
                paramsSerializer: (params) =>{
                    return qs.stringify(params);
                }
            })
            commit('changeUsers', result.data);
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    editUser: async ({commit}, payload) => {
        try {
            const result = await axios.post('/users/update/', payload)
            commit('changeUser', result.data);
            return{
                status: true,
                data: result.data
            }
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    getUsersGroup: async ({commit}, options) => {
        try {
            const result = await axios.get('/user_groups',{
                params: options,
                paramsSerializer: (params) =>{
                    return qs.stringify(params);
                }
            })
            commit('changeUsersGroup', result.data);
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    showUserGroup: async ({commit}, id) => {
        try {
            const result = await axios.get('/user_groups/'+id)
            commit('changeUserGroup', result.data);
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    editUserGroup: async (ctx, payload) =>{
        try{
            await axios.put('/user_groups/'+payload.id, payload);
            return {
                status: true,
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
    addUserGroup: async (ctx, payload) =>{
        try{
            const result = await axios.post('/user_groups', payload);
            return {
                status: true,
                data: result.data
            }
        }catch (e){
            console.log('result e - ', e);
            throw e
        }
    },
    removeUserGroup: async ({commit},id) =>{
        try{
            const result = await axios.post('/user_groups/'+id,{
                "_method" : "DELETE"
            });
            commit('changes', result.data.model);
            return {
                status: true,
            }
        }catch (e){
            console.log(e)
            throw e
        }
    },
}

const mutations ={
    changeCustomers(state, status) {
        state.customers = status;
    },
    changeCustomer(state, status) {
        state.customer = status;
    },
    changeCustomerGroups(state, status) {
        state.customerGroups = status;
    },
    changeCustomerGroup(state, status) {
        state.customerGroup = status;
    },
    changeDashboard(state, status) {
        state.dashboard = status;
    },
    changeUsers(state, status){
        state.users = status.models;
    },
    changeUser(state, id){
        if(id && state.users){
            state.users.map(item =>{
                if(item.id === id){
                    state.user = item;
                }
            })
        }else{
            state.user = null
        }
    },
    changeUsersGroup(state, status){
        state.usersGroup = status.models;
    },
    changeUserGroup(state, status){
        state.userGroup = status.models;
    },
    changes(){},

};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

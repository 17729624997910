import axios from 'axios';

axios.defaults.headers = {
    'Authorization':'Bearer ' + localStorage.getItem('token'),
    'Accept':'application/json',
}

const state = {
    lots: null,
    npAreas: null,
    npCity: null,
    npWarehouses: null,
    counterpartySender: null,
    counterpartyAddress: null,
};

const getters = {
    lots: state => state.lots,
    npAreas: state => state.npAreas,
    npCity: state => state.npCity,
    npWarehouses: state => state.npWarehouses,
    counterpartySender: state => state.counterpartySender,
    counterpartyAddress: state => state.counterpartyAddress,
};

const actions = {
    getLots: async({commit}, options) => {
        let url = '/lots'
        if(options) {
            url = `/lots?page=${options.page}&per_page=${options.per_page}`
        }
        try {
            const result = await axios.get(url)
            commit('changeLots', result.data.models)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    getNpAreas: async ({commit}) =>{
        try{
            const result = await axios.post('/np/areas/for_select')
            commit('changeNpAreas', result.data)
            return {
                status: true,
                data: result.data
            }
        } catch (e){
            console.log(e);
            throw e;
        }
    },
    getNpCitySearch: async ({commit}, query) => {
        try{
            const result = await axios.post('/np/cities/for_select_by', {search: query})
            commit('changeNpCity', result.data)
            return {
                status: true,
                data: result.data
            }
        }
        catch (e) {
            console.log(e)
            throw e;
        }
    },
    getNpCity: async ({commit}, id) =>{
        try{
            const result = await axios.post('/np/cities/for_select', id)

            commit('changeNpCity', result.data)
            return {
                status: true,
                data: result.data
            }
        } catch (e){
            console.log(e);
            throw e;
        }
    },
    getNpWarehouses: async ({commit}, id) =>{
        try{
            const result = await axios.post('/np/warehouses/for_select', id)
            commit('changeNpWarehouses', result.data)
            return {
                status: true,
                data: result.data
            }
        } catch (e){
            console.log(e);
            throw e;
        }
    },
    getCounterpartySender: async ({commit}, data) =>{
        try{
            const result = await axios.post('/np/counterparty_sender/for_select', data)
            commit('changeCounterpartySender', result.data)
            return result.data
        }catch (e){
            console.log('e', e);
            throw e;
        }
    },
    getCounterpartyAddress: async ({commit},payment) =>{
        try{
            const result = await axios.post('/np/counterparty_address/for_select',payment)
            commit('changeCounterpartyAddress', result.data)
        }catch (e){
            console.log('e', e);
            throw e;
        }
    }
}

const mutations ={
    changeLots(state, status) {
        state.lots = status;
    },
    changeNpAreas(state, status) {
        state.npAreas = status;
    },
    changeNpCity(state, status) {
        state.npCity = status;
    },
    changeNpWarehouses(state, status) {
        state.npWarehouses = status;
    },
    changeCounterpartySender(state, status){
        state.counterpartySender = status;
    },
    changeCounterpartyAddress(state, status){
        state.counterpartyAddress = status;
    }

};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

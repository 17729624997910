import axios from 'axios';

axios.defaults.headers = {
    'Authorization':'Bearer ' + localStorage.getItem('token'),
    'Accept':'application/json',
}

const state = {
    rate: null,
    actualRate: null,
};

const getters ={
    rate: state => state.rate,
    actualRate: state => state.actualRate,
};

const actions = {
    getRate: async({commit}, options) => {
        let url = '/currencies'
        if(options) {
            url = `/currencies?page=${options.page}&per_page=${options.per_page}`
        }
        try {
            const result = await axios.get(url)
            commit('changeRate', result.data.models)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    getRateActual: async({commit}) => {
        try {
            const result = await axios.get('/currencies/actual')
            commit('changeActualRate', result.data.models)
        } catch (e) {
            console.log(e)
            throw e
        }
    },
    sendNewRate: async(ctx, payload) => {
        try {
            const result = await axios.post('/currencies/store', payload)
            return {
                status: true,
                data: result.data
            }
        } catch (e) {
            console.log(e)
            throw e
        }
    },
}

const mutations ={
    changeRate(state, status) {
        state.rate = status;
    },
    changeActualRate(state, status) {
        state.actualRate = status;
    },

};



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};

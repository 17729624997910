import {mapActions, mapGetters, mapMutations} from 'vuex';

const _ = require('lodash');

export default {
    data(){
      return{
          newMenuList: null
      }
    },
    computed: {
        ...mapGetters({
            menuList:'config/menuList',
            menuHide:'config/menuHide',
            openMenuItem:'config/openMenuItem',
            colNewOrders:'orders/colNewOrders',
            profile:'profile/profile',
            usersGroup: 'contacts/usersGroup',

        })
    },
    watch:{
        usersGroup(e){
            if(this.profile){
                let userGroup = e.data.filter(item=>item.id === this.profile.user_group_id)
                userGroup = userGroup[0];
                this.newMenuList = _.clone(this.menuList);

                this.newMenuList.map(menuItem => {
                    let colValueTrue = 0;
                    if(menuItem.list){
                        menuItem.list.map(listItem=>{
                            userGroup.accept_to_menu_items.map(item=>{
                                if(listItem.name === item.item_name){
                                    listItem.value = item.value
                                }
                                if(listItem.value){
                                    colValueTrue++;
                                }
                            })
                        })
                        menuItem.value = colValueTrue;
                    }
                })
                this.newMenuList[0].value = 1;
                return userGroup;
            }
        }
    },
    created() {
        this.getOrders().then(()=>{
            this.getUsersGroup();
        });

    },
    methods:{
        showMenuList(id){
            this.changeOpenMenuItem(id);
            this.toggleMenuHide(false);
        },
        toggleSideBar(){
            this.changeOpenMenuItem(0);
            this.toggleMenuHide(!this.menuHide);
        },
        ...mapActions({
            getOrders:'orders/getOrders',
            getUsersGroup:'contacts/getUsersGroup',
        }),
        ...mapMutations({
            toggleMenuHide:'config/toggleMenuHide',
            changeOpenMenuItem:'config/changeOpenMenuItem',
        })
    }
}

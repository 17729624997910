import {mapActions, mapGetters, mapMutations} from 'vuex';
import Header from '../../components/header/index.vue'
import leftBar from '../../components/left-bar/index.vue'
import Table from '../../components/table/list/index.vue'
import TableOptions from '../../components/table/options/index.vue'
import provisionersPopup from '@/components/popups/provisioners/index.vue'
import pagination from '@/components/pagination/index.vue'
import paginationMixin from '@/mixins/pagination/index.js'

export default {
    components:{
        Header,
        leftBar,
        Table,
        TableOptions,
        provisionersPopup,
        pagination
    },
    mixins: [paginationMixin],
    data(){
        return{
            fields:[
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    provisioner:'Организация',
                },
                {
                    name:'Контактное лицо',
                },
                {
                    provisionersPhone:'Мобильный телефон',
                },
                {
                    email:'email',
                },
                {
                    provisionerData:'Добавленно',
                },
                {
                    card_number:'Банковская карта',
                },
            ],
            items:[],
            addProvisionersPopup: false,
            selectedItem: null,
        }
    },
    computed: {
        ...mapGetters({
            menuHide:'config/menuHide',
            provisioners:'warehouse/provisioners',
        })
    },
    watch:{
        provisioners(e){
            this.items = []
            e.data.map(item=>{
                this.items.push({
                    id:item.id,
                    provisioner: item.title,
                    name:item.name,
                    provisionersPhone: item.phone,
                    email: item.email,
                    provisionerData: this.$moment(item.created_at).format('YYYY.MM.DD  HH:mm:ss'),
                    card_number: item.card_number
                });
            })
            this.res = e
        },
        page(e) {
            this.getProvisioners({page: e, perpage: this.perPage});
        },
        perPage(e) {
            this.getProvisioners({page: this.page, perpage: e});
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getProvisioners({page: 1, perpage: this.perPage}).then(()=>{
            this.toggleLoadingStatus(false);
        })
    },
    methods:{
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        changePopup(item){ 
            this.showProvisioner(item.id)
            this.addProvisionersPopup = true;
        },
        sendProvisioner(form){
            this.addProvisioners(form).then(res=>{
                if(res.status){
                    this.$toasted.success('Поставщик у',{
                        duration : 3000
                    });
                }
                this.getProvisioners();
                this.addProvisionersPopup = false;
            })
        },
        changeProvisioner(form){
            this.editProvisioner(form).then(res=>{
                if(res.status){
                    this.$toasted.success('Поставшик успешно изменен',{
                        duration : 3000
                    });
                }
                this.getProvisioners();
                this.addProvisionersPopup = false;
            })
        },
        removeItem(id){
            this.removeProvisioner(id).then(res=>{
                if(res.status){
                    this.getProvisioners();
                    this.$toasted.success('Поставшик успешно удален',{
                        duration : 3000
                    });
                    this.addProvisionersPopup = false;
                }
            })
        },
        ...mapActions({
            getProvisioners:'warehouse/getProvisioners',
            addProvisioners:'warehouse/addProvisioners',
            showProvisioner:'warehouse/showProvisioner',
            editProvisioner:'warehouse/editProvisioner',
            removeProvisioner:'warehouse/removeProvisioner',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    }

}
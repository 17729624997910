import {mapActions, mapGetters, mapMutations} from 'vuex';
import paginationMixin from '@/mixins/pagination/index.js'
import filterSearch from '@/mixins/filter-search/index.js'
import * as moment from "moment/moment";

export default {
    components:{
        Header: ()=> import('@/components/header/index.vue'),
        leftBar: ()=> import('@/components/left-bar/index.vue'),
        Table: ()=> import('@/components/table/list/index.vue'),
        pagination: ()=> import('@/components/pagination/index.vue'),
        TableOptions: ()=> import('@/components/table/options/index.vue'),
        popup: ()=> import('@/components/popups/contacts/customers/index.vue'),
        carousel: ()=> import('vue-owl-carousel'),
    },
    mixins: [paginationMixin, filterSearch],
    data(){
        return{
            fields:[
                {
                    key: 'customer_id',
                    label: 'id'
                },
                {
                    key: 'name',
                    label: 'Клиент'
                },
                {
                    key: 'country',
                    label: 'Локализация'
                },
                {
                    key: 'mobile',
                    label: 'Телефон'
                },
                {
                    key: 'customer_group_id',
                    label: 'Группа'
                },
                {
                    key: 'email',
                    label: 'email'
                },
                {
                    key: 'comment',
                    label: 'Комментарий'
                },
                {
                    key: 'added',
                    label: 'Добавлено'
                },
                {
                    key: 'web-site',
                    label: 'Сайт'
                },
                {
                    key: 'ip',
                    label: 'IP-адрес'
                }
            ],
            items:[],
            selectedItem: null,
            showPopup: false,
            activeStatus: 0,
        }
    },
    computed: {
        ...mapGetters({
            menuHide:'config/menuHide',
            customers:'contacts/customers',
            customerGroups:'contacts/customerGroups',
        })
    },
    watch:{
        customers(e){
            this.items = []
            e.data.map(item=>{
                let customGroup = this.customerGroups.data.filter(itemGroup => itemGroup.id === item.customer_group_id);
                if(customGroup.length !== 0){
                    customGroup = customGroup[0].title
                }else{
                    customGroup = ''
                }
                this.items.push({
                    customer_id:item.id,
                    'name': item.name,
                    'country': item.country,
                    'mobile': item.phone,
                    'customer_group_id': customGroup,
                    'email': item.email,
                    'comment': item.comment,
                    'added':  moment(item.created_at).format('YYYY-MM-DD HH:mm'),
                    'web-site': item.site,
                    'ip': item.ip,
                });
            })
            this.res = e
        },
        page() {
            this.searchPayload.page = this.page
            this.searchPayload.per_page = this.per_page
            this.getCustomers(this.searchPayload);
        },
        per_page(e) {
            this.searchPayload.per_page = e
            this.getCustomers(this.searchPayload);
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getCustomerGroups().then(()=>{
            this.getCustomers(this.searchPayload).then(()=>{
                this.toggleLoadingStatus(false);
            });
        })
    },
    methods:{
        removeFilterItem(index){
            this.filterList.splice(index, 1);
            this.createPayloadForSearch();
        },
        getPayloadForSearch(){
            if(this.activeStatus !== 0){
                this.searchPayload['customer_group_id'] = this.activeStatus
                    this.getCustomers(this.searchPayload);

            }else{
                this.getCustomers(this.searchPayload);

            }
        },
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        changePopup(item){
            this.showCustomer(item.customer_id)
            this.showPopup = true;
        },
        editClickWarehouse(item){
            this.showCustomer(item.id)
            this.showPopup = true;
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        sendCustomer(form){
            this.addCustomer(form).then(res=>{
                if(res.status){
                    this.getCustomers(this.searchPayload)
                    this.$toasted.success('Клиент успешно добавлен',{
                        duration : 3000
                    });
                }
                this.showPopup = false;
            })
        },
        changeCustomer(form){
            this.editCustomer(form).then(res=>{
                if(res.status){
                    this.getCustomers(this.searchPayload)
                    this.$toasted.success('Клиент успешно изменен',{
                        duration : 3000
                    });
                    this.showPopup = false;
                }
            })
        },
        removeItem(id){
          this.removeCustomer(id).then(res=>{
              if(res.status){
                  this.getCustomers(this.searchPayload)
                  this.$toasted.success('Клиент успешно удален',{
                      duration : 3000
                  });
                  this.showPopup = false;
              }
          })
        },
        getClientFromStatus(id){
            this.activeStatus = id;
            this.searchPayload['customer_group_id'] = this.activeStatus
            this.getCustomers(this.searchPayload)
        },
        ...mapActions({
            getCustomers:'contacts/getCustomers',
            showCustomer:'contacts/showCustomer',
            editCustomer:'contacts/editCustomer',
            addCustomer:'contacts/addCustomer',
            removeCustomer:'contacts/removeCustomer',
            getCustomerGroups:'contacts/getCustomerGroups',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    }
}

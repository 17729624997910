import {mapGetters, mapActions, mapMutations} from "vuex";

export default {
    data(){
      return{
          changeRate: false,
          currency_value: 0,
          fields: [
              {
                  key: 'id',
                  label: 'id'
              },
              {
                  key: 'rate',
                  label: 'Курс'
              },
              {
                  key: 'employee_id',
                  label: 'Сотрудник'
              },
              {
                  key: 'created_at',
                  label: 'Дата создания'
              },
          ],
          items:[],
      }
    },
    components: {
        Header: () => import('@/components/header/index.vue'),
        leftBar: () => import('@/components/left-bar/index.vue'),
    },
    computed: {
        ...mapGetters({
            menuHide: 'config/menuHide',
            actualRate: 'rate/actualRate',
            rate: 'rate/rate',
            users:'contacts/users',
        })
    },
    watch:{
        rate(e){
            if(e && e.data.length){
                e.data.map(item =>{
                    let actualUser = this.users.filter(userItem=> userItem.id === item.employee_id)
                    actualUser = actualUser[0]
                    this.items.push({
                        id: item.id,
                        rate: Number(item.currency_value).toFixed(2),
                        created_at: this.$moment(item.created_at).format('YYYY-MM-DD HH:mm:ss'),
                        employee_id: actualUser.name,
                    })
                })
            }
        }
    },
    created() {
        this.getUsers().then(()=>{
            this.getRate();
        });
        this.getRateActual();
    },
    methods:{
        sendChangeRate(){
          this.sendNewRate({
              currency_value: this.currency_value
          }).then(res=>{
              if(res.status){
                  this.changeRate = true
                  this.changeRateMutation(null);
                  this.getRate();
                  this.getRateActual().then(()=>{
                      this.currency_value = this.actualRate.value;
                  });
              }
          })
        },
        openChangeRate(){
            this.changeRate = true;
            this.currency_value = this.actualRate.value;
        },
        ...mapActions({
            getRate:'rate/getRate',
            sendNewRate:'rate/sendNewRate',
            getRateActual:'rate/getRateActual',
            getUsers:'contacts/getUsers',
        }),
        ...mapMutations({
            changeRateMutation:'rate/changeRate'
        })
    },

}
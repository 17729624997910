export default {
    name: 'Pagination',
    props: ['res'],
    data() {
        return {
            per_page: 50,
            page: 1,
            pages: {
                pageBegin: 1,
                pageEnd: 1,
            }
        }
    },
    mounted(){
        this.updatePagination();
    },
    watch:{
        res(){
          this.updatePagination();  
        } 
    },
    methods: {
        updatePagination(){ 
            if(this.res.current_page === 1 && this.res.last_page - this.res.current_page < 5){
                this.pages.pageEnd = this.res.last_page
            }else if(this.res.current_page !== 1 && this.res.last_page - this.res.current_page < 2){
                this.pages.pageBegin = this.res.current_page - 3 > 1 ? this.res.current_page - 3  : 1
                this.pages.pageEnd = this.res.last_page
            }else if(this.res.current_page === 1 && this.res.last_page - this.res.current_page > 5 ){
                this.pages.pageEnd = 5
            }else if(this.res.current_page !== 1 && this.res.last_page - this.res.current_page > 2){
                this.pages.pageBegin = this.res.current_page - 2 > 1 ? this.res.current_page - 2  : 1
                this.pages.pageEnd = this.res.current_page + 3 < this.res.last_page ? this.res.current_page + 3 : this.res.last_page
            }
        },
        range(start, end) {
            const arr = []
            for(let i = start; i <= end; i++) {
                arr.push(i)
            }
            return arr
        },
        changePerPageCount() {
            this.$emit('changePerPage', this.res.per_page)
        },
        changePage(i) {
            this.$emit('changePage', i)
        },
        lastPage() {
            this.$emit('toLastPage', this.res.last_page)
        },
        firstPage() {
            this.$emit('changePage', 2)
            setTimeout(() => {
                this.$emit('prevPage', 1)
            },30)
        },
        nextPage() {
            this.$emit('nextPage', this.res.current_page+1)
        },
        prevPage() {
            this.$emit('prevPage', this.res.current_page-1)
        }
    }
}

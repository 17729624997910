import {mapGetters, mapActions, mapMutations} from 'vuex';
import NotificationsPopup from '@/components/popups/notification/index.vue'
import OptionsPopup from '@/components/popups/options/index.vue'

export default {
    components: {
        NotificationsPopup,
        OptionsPopup
    },
    data(){
      return{
          useBrowse:'',
          useOs: '',
          toggleProfileModal:false,
          showNotificationModal: false,
          showOptionsPopup: false,
      }
    },
    computed: {
        ...mapGetters({
            menuHide:'config/menuHide',
            profile:'profile/profile',
            notifications: 'notification/notifications',
            colNewOrders:'orders/colNewOrders'
        }),
    },
    created() {
        this.getProfile();
        this.getUserBrowser();
        this.getUserOs();
        this.getNotifications();
    },
    updated () {
        this.notifications.map( item => {
            if(this.$moment(item.date_to).unix() < this.$moment().unix()) {
                const result = {...item, expired: true}
                this.editNotification(result)
                return result
            }
            return {...item}
        })
    },
    methods:{
        showOptions() {
            this.showOptionsPopup = true
        },
        closePopup() {
            this.showNotificationModal = false
        },
        getUserBrowser(){
            let sUsrAg = navigator.userAgent;
            if (sUsrAg.indexOf("Firefox") > -1) {
                this.useBrowse = "Mozilla Firefox";
                //"Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
            } else if (sUsrAg.indexOf("Opera") > -1) {
                this.useBrowse = "Opera";
            } else if (sUsrAg.indexOf("Trident") > -1) {
                this.useBrowse = "Microsoft Internet Explorer";
                //"Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C; .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
            } else if (sUsrAg.indexOf("Edge") > -1) {
                this.useBrowse = "Microsoft Edge";
                //"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
            } else if (sUsrAg.indexOf("Chrome") > -1) {
                this.useBrowse = "Google Chrome or Chromium";
                //"Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
            } else if (sUsrAg.indexOf("Safari") > -1) {
                this.useBrowse = "Apple Safari";
                //"Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
            } else {
                this.useBrowse = "unknown";
            }
        },
        getUserOs(){
            let OSName = "Unknown";
            if (window.navigator.userAgent.indexOf("Windows NT 10.0")!= -1) OSName="Windows 10";
            if (window.navigator.userAgent.indexOf("Windows NT 6.2") != -1) OSName="Windows 8";
            if (window.navigator.userAgent.indexOf("Windows NT 6.1") != -1) OSName="Windows 7";
            if (window.navigator.userAgent.indexOf("Windows NT 6.0") != -1) OSName="Windows Vista";
            if (window.navigator.userAgent.indexOf("Windows NT 5.1") != -1) OSName="Windows XP";
            if (window.navigator.userAgent.indexOf("Windows NT 5.0") != -1) OSName="Windows 2000";
            if (window.navigator.userAgent.indexOf("Mac")            != -1) OSName="Mac/iOS";
            if (window.navigator.userAgent.indexOf("X11")            != -1) OSName="UNIX";
            if (window.navigator.userAgent.indexOf("Linux")          != -1) OSName="Linux";
            this.useOs = OSName;
        },
        actualLengthNotifications(remember){
            let count = 0;
            remember.map(item=>{
                if(item.status === 0){
                    count++;
                }
            })
            return count;
        },
        ...mapActions({
            getProfile:'profile/getProfile',
            getNotifications: 'notification/getNotifications',
            editNotification: 'notification/editNotification',
        }),
        ...mapMutations({
            changeToggleExitConfirm: 'config/changeToggleExitConfirm'
        })
    }
}
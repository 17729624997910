import {mapGetters, mapActions, mapMutations} from 'vuex';
import Header from '@/components/header/index.vue'
import leftBar from '@/components/left-bar/index.vue'
import TableOptions from '@/components/table/options/index.vue'
import Table from '@/components/table/list/index.vue'
import productPricePopup from '@/components/popups/catalog/department/index.vue'
import pagination from '@/components/pagination/index.vue'
import paginationMixin from '@/mixins/pagination/index.js'

export default {
    components:{
        Header,
        leftBar,
        TableOptions,
        Table,
        productPricePopup,
        pagination,
    },
    mixins: [paginationMixin],
    data(){
        return{
            fields:[
                {
                    key: 'id',
                    label: 'ID'
                },
                {
                    key:'departmentTitle',
                    label:'Название',
                },
                {
                    key:'sort',
                    label:'Сорт',
                },
            ],
            items:[],
            selectedItem: null,
            showDepartmentPopup: null,
            showRemoveConfirmPopup: false
        }
    },
    computed:{
        ...mapGetters({
            menuHide:'config/menuHide',
            departments:'catalog/departments',
        })
    },
    watch:{
        departments(e){
            this.items = []
            e.data.map(item=>{
                this.items.push({id: item.id, 'departmentTitle': item.title, 'sort': item.sort})
            })
           this.res = e
        },
        page(e) {
            this.getDepartments({page: e, per_page: this.per_page});
        },
        per_page(e) {
            this.getDepartments({page: this.page, per_page: e});
        }
    },
    created() {
        this.toggleLoadingStatus(true);
        this.getDepartments({page: 1, per_page: this.per_page}).then(()=>{
            this.toggleLoadingStatus(false);
        })
    },

    methods:{
        getSearchInfo(data){
            if(data.query){
                this.getDepartments({[data.type]: data.query});
            }else{
                this.getDepartments();
            }
        },
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        sendDepartment(item){
            this.addDepartment(item).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Отдел успешно добавлен', {
                        duration: 3000
                    });
                    this.showDepartmentPopup = false;
                    this.getDepartments();
                }
            });
        },
        changeDepartment(item){
            this.editDepartment(item).then(res=>{
                if(res.status){
                    this.items = [];
                    this.$toasted.success('Отдел успешно обновлен', {
                        duration: 3000
                    });
                    this.showDepartmentPopup = false;
                    this.getDepartments();
                }
            });
        },
        toggleStatus(item){
            const payload = {
                id: item.id,
                data:{
                    title:item.title,
                    status:item.status
                }
            }
            this.editDepartment(payload)

        },
        selectItem(item){
            if(item.length) {
                this.selectedItem = item;
            // }else if (item.length > 1) {
            //     this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        editClickDepartment(item){
            this.showDepartment(item.id)
            this.showDepartmentPopup = true;
        },
        removeItem(id){
            this.removeDepartment(id).then(res=>{
                if(res.status){
                    this.items = [];
                    this.getDepartments();
                    this.$toasted.success('Отдел успешно удален',{
                        duration : 3000
                    });
                    this.showPriceCategoryPopup = false;
                }
            })
        },
        ...mapActions({
            getDepartments:'catalog/getDepartments',
            showDepartment:'catalog/showDepartment',
            addDepartment:'catalog/addDepartment',
            editDepartment:'catalog/editDepartment',
            removeDepartment:'catalog/removeDepartment',
        }),
        ...mapMutations({
            toggleLoadingStatus: 'config/toggleLoadingStatus',
        })
    }
}

import {mapActions, mapGetters} from 'vuex';

export default {
    components:{
        Header: () => import('@/components/header/index.vue'),
        leftBar: () => import('@/components/left-bar/index.vue'),
        TableOptions: () => import('@/components/table/options/index.vue'),
        Table: () => import('@/components/table/list/index.vue'),
        popup: () => import('@/components/popups/contacts/userGroup/index.vue'),
        pagination: () => import('@/components/pagination/index.vue'),
    },
    data(){
      return{

          fields:[
              {
                  key: 'id',
                  label: 'id'
              },
              {
                  key: 'users_title',
                  label: 'Название'
              },
              {
                  key: 'description',
                  label: 'Описание'
              },
              {
                  key: 'sort',
                  label: 'sort'
              },
          ],
          items:[],
          selectedItem: null,
          showPopup: false
      }
    },
    computed:{
      ...mapGetters({
          menuHide:'config/menuHide',
          usersGroup:'contacts/usersGroup'
      })
    },
    watch:{
        usersGroup(e){
            this.items = []
            e.data.map(item=>{
                this.items.push({
                    id:item.id,
                    users_title: item.title,
                    description: item.description,
                    sort: item.id,
                });
            })
            this.res = e;
        },
    },
    created() {
        this.getUsersGroup();
    },
    methods:{
        changePopup(item){
            this.showUserGroup(item.id)
            this.showPopup = true;
        },
        sendCustomerGroup(form){
            this.addUserGroup(form).then(res=>{
                if(res.status){
                    this.getUsersGroup();
                    this.$toasted.success('Группа успешно добавлена',{
                        duration : 3000
                    });
                }
                this.showPopup = false;
            })
        },
        getSearchInfo(data){
            if(data.type === 'users_title'){
                data.type = 'title'
            }
            if(data.query){
                this.getUsersGroup({[data.type]: data.query});
            }else{
                this.getUsersGroup();
            }
        },
        changeCustomerGroup(form){
            this.editUserGroup(form).then(res=>{
                if(res.status){
                    this.getUsersGroup();
                    this.$toasted.success('Группа успешно изменена',{
                        duration : 3000
                    });
                    this.showPopup = false;
                }
            })
        },
        editClickWarehouse(item){
            this.showUserGroup(item.id)
            this.showPopup = true;
        },
        selectItem(item){
            if(item.length){
                this.selectedItem = item;
            }else{
                this.selectedItem = null;
            }
        },
        getAllItemsInTable(){
            this.selectedItem = this.items;
            this.$refs.tableComponent.getAllItemsInTable();
        },
        removeItem(id){
            this.removeUserGroup(id).then(res=>{
                if(res.status){
                    this.getUsersGroup();
                    this.$toasted.success('Група успешно удалена',{
                        duration : 3000
                    });
                    this.showPopup = false;
                }
            })
        },
        ...mapActions({
            getUsersGroup:'contacts/getUsersGroup',
            showUserGroup:'contacts/showUserGroup',
            editUserGroup:'contacts/editUserGroup',
            addUserGroup:'contacts/addUserGroup',
            removeUserGroup:'contacts/removeUserGroup',
        })
    }
}
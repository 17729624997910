import {mapGetters} from 'vuex';

const _ = require('lodash');

export default {
    props:{
        fields:{
            type:Array || Object,
            default: null,
        },
        items:{
            type:Array || Object,
            default: null
        },
        resStyle:{
            type: String,
            default: null
        },
        filterList: {
            type: Boolean || Number,
            default: false,
        },
    },
    data(){
        return{
            selected: [],
            selectMode: 'single',
            dabbleUse: false,
        }
    },
    computed:{
        ...mapGetters({
            users: 'contacts/users',
            baseUrl: 'config/baseUrl',
            departments:'catalog/departments',
            paymentMethods:'orders/paymentMethods',
            shipmentMethods:'orders/shipmentMethods',
        }),
        filtered () {
            return this.items
        },
        filters: {
            get: function() {
                if(!this.items.length) {
                    return {}
                }
                let result = {}
                Object.keys(this.items[0]).forEach(el => {
                    result[el] = ''
                })
                return result
            }
        }
    },
    watch: {
        selectedItem(e){
            if(e.length === this.items.length)
            this.selectMode = 'multi'
            this.selected = e;
            this.$refs.selectableTable.selectAllRows()
        },
    },
    mounted() {
        window.addEventListener('keydown', e => {
            // console.log(e)
            if(e.altKey) {
              this.selectMode = 'multi'
            }
            // console.log(e)
        })
        window.addEventListener('keyup', () => {
            document.getElementById('selectableTable').addEventListener('mousedown', event => {
                if(!event.altKey) {
                    this.selectMode = 'single'
                }
            })
        })
    },
    methods: {
        getAllItemsInTable(){
            this.selectMode = 'multi'
            this.selected = this.items
            setTimeout(()=>{
                this.$refs.selectableTable.selectAllRows()
            },100)
        },
        filterSearch: _.debounce(function (data){
            this.$emit('getSearchInfo', data);
        },500),
        changeStatus(id, status){
            this.$emit('changeItemStatus', {id, status})
        },
        showWhatChanges(id){
            this.$emit('showWhatChanges', id)
        },
        sendLackOfItem(id){
            this.$emit('sendLackOfItem', id)
        },
        rowClass(item, type){
            if (!item || type !== 'row') return
            if( item.hidden_class) return item.hidden_class
            if( item.lock) return 'lock-block'
            if( item.status_open === 1) return 'status-block color-tr'
            if( item.product_color) return 'color-tr'
            if( item.shipment_checkout === true) return 'checked-shipment'
            if( item.lack_less_quantity > 0) return 'warning'
            else if(item.lack_less_quantity === 0) return 'error'
        },
        rowAttr(item, type){
            if (!item || type !== 'row') return
            if(item.product_color) return {'style':"background:"+item.product_color+""}
        },
        onRowSelected(items) {
            this.$emit('selectItem', items)
            this.selected = items
        },
        onRowDoubleClicked(item){
            this.$emit('changePopup', item)
        },
        filterShipmentMethods(item){
            this.$emit('getSearchInfo', {'type': 'shipment_method_id', 'query': JSON.stringify(item)});
        },
        filterPaymentMethods(item){
            this.$emit('getSearchInfo', {'type': 'payment_method_id', 'query': JSON.stringify(item)});
        },
        filterUsers(item){
            this.$emit('getSearchInfo', {'type': 'employee_id', 'query': JSON.stringify(item)});
        },
        filterDepartment(item){
            this.$emit('getSearchInfo', {'type': 'department_id', 'query': JSON.stringify(item)});
        },
        toggleStatus(item){
            item.status = Number(item.status);
            this.$emit('toggleStatus', item);
        }
    }
}